import Navigation from "../../components/Navigation";

const Internal = () => {
    return (
        <div>
            <h1>Start Of Dashboard</h1>
            <Navigation/>
        </div>
    );
}

export default Internal