import React from 'react';

const PreviewText = ({ text, change }) => (
  <div className="form-row">
    <input
      type="text"
      className="form-control"
      placeholder="Preview text"
      value={text}
      onChange={(e) => {
        const { target } = e;
        const { value } = target;
        change(value);
      }}
    />
  </div>
);

export default PreviewText;
