import React from 'react';

const DefaultInput = ({hook, placeholder, id, value, changeInput}) => (
    <div className="form-row">
        <input
            type="text"
            data-hook={hook || {}}
            className="form-control"
            placeholder={placeholder}
            name={id}
            value={value}
            onChange={(e) => {
                e.preventDefault();
                const {target} = e;
                const {name: fieldTypeId, value, type} = target;
                return changeInput(fieldTypeId, value, type, target);
            }}
        />
    </div>
);

export default DefaultInput;
