import React from 'react';

const Checkbox = ({ id, hook, changeInput, placeholder, value }) => (
  <div className="form-check">
    <label htmlFor={id} className="form-check-label">
      <input
        style={{ zIndex: '100', height: '10px', width: '10px' }}
        type="checkbox"
        className="form-check-input"
        name={id}
        data-hook={hook || {}}
        checked={value}
        onChange={(e) => {
          const { target } = e;
          const { name: fieldTypeId, value, type } = target;
          return changeInput(fieldTypeId, value, type, target);
        }}
      />{' '}
      <span
        className="checkmark"
        onChange={(e) => {
          const { target } = e;
          const { name: fieldTypeId, value, type } = target;
          return changeInput(fieldTypeId, value, type, target);
        }}
      ></span>
    </label>
    {placeholder}
  </div>
);

export default Checkbox;
