const storageDarkMode = localStorage.getItem("data-theme");
let darkMode = false;
if (storageDarkMode === "dark") {
    document.documentElement.setAttribute("data-theme", "dark");
    darkMode = true;
} else {
    document.documentElement.setAttribute("data-theme", "light");
}

const initState = {
    templateData: null,
    templateStyle: null,
    hbsTmplFunc: null,
    hbsTmplHeaderFunc: null,
    templateId: 0,
    prevNewsletterId: 0,
    templates: null,
    brands: null,
    brandId: 0,
    tagline: '',
    releaseDate: '',
    releaseDateFull: null,
    previewText: '',
    mailingListId: 0,
    mailingLists: null,
    footers: [],
    footerId: null,
    darkMode: darkMode
};

const NewsletterReducer = (state = initState, action) => {
    switch (action.type) {
        /**
         * Something has been added to one of the inputs in the editor menu
         */
        case 'EDITOR_INPUT_CHANGE': {
            const {subTypeKey, fieldTypeKey, value} = action;
            const {templateData} = state;
            return {
                ...state,
                templateData: {
                    ...templateData,
                    subTypes: {
                        ...templateData.subTypes,
                        [subTypeKey]: {
                            ...templateData.subTypes[subTypeKey],
                            [fieldTypeKey]: {
                                ...templateData.subTypes[subTypeKey][fieldTypeKey],
                                value: value,
                            },
                        },
                    },
                },
            };
        }

        case 'TOGGLE_DARK_MODE': {
            const l = !state.darkMode ? "dark" : "light"
            document.documentElement.setAttribute("data-theme", l);
            localStorage.setItem("data-theme", l);
            return {
                ...state,
                darkMode: !state.darkMode
            }
        }

        case 'SUCCESS_SAVING_NEWSLETTER': {
            const {newsletterId} = action;
            return {
                ...state,
                prevNewsletterId: newsletterId,
            };
        }

        /**
         * A subtype has been opened up
         */
        case 'TOGGLE_SUB_TYPE_OPEN': {
            const {templateData} = state;
            const {subTypeKey} = action;
            return {
                ...state,
                templateData: {
                    ...templateData,
                    subTypes: {
                        ...templateData.subTypes,
                        [subTypeKey]: {
                            ...templateData.subTypes[subTypeKey],
                            isOn: !templateData.subTypes[subTypeKey].isOn,
                        },
                    },
                },
            };
        }

        case 'TEMPLATE_CHANGE': {
            const {
                templateId,
                hbsTmplFunc,
                hbsTmplHeaderFunc,
                templateStyle,
                templateData,
            } = action;
            return {
                ...state,
                templateId,
                hbsTmplFunc,
                hbsTmplHeaderFunc,
                templateStyle,
                templateData,
            };
        }

        case 'PREV_NEWSLETTER_ID_CHANGE': {
            const {prevNewsletterId} = action;
            return {
                ...state,
                prevNewsletterId,
            };
        }

        case 'SUCCESS_PREV_NEWSLETTER': {
            const {
                templateId,
                brandId,
                prevNewsletterId,
                releaseDate,
                releaseDateFull,
                tagline,
                templateStyle,
                templateData,
                hbsTmplHeaderFunc,
                hbsTmplFunc,
            } = action;

            return {
                ...state,
                templateId,
                prevNewsletterId,
                releaseDate,
                releaseDateFull,
                templateStyle,
                templateData,
                hbsTmplFunc,
                hbsTmplHeaderFunc,
                tagline,
                brandId,
            };
        }

        case 'SET_TEMPLATES': {
            return {
                ...state,
                templates: action.templates,
            };
        }

        case 'SET_BRANDS': {
            return {
                ...state,
                brands: action.brands,
            };
        }

        case 'BRAND_CHANGE': {
            const {brands} = state;
            const {brandId} = action;
            const brand = brands.find((brand) => brand.id === brandId);
            const {tagline} = brand;

            return {
                ...state,
                brandId,
                tagline,
            };
        }


        case 'CHANGE_TAGLINE': {
            const {tagline} = action;
            return {
                ...state,
                tagline,
            };
        }

        case 'CHANGE_RELEASE_DATE': {
            const {releaseDate, releaseDateFull} = action;
            return {
                ...state,
                releaseDate,
                releaseDateFull,
            };
        }

        case 'SUCCESS_FILL_FROM_YOUTUBE_LINK': {
            const {title, copy, logoImageUrl, landPageUrl, subTypeKey} = action;

            const {templateData} = state;
            return {
                ...state,
                templateData: {
                    ...templateData,
                    subTypes: {
                        ...templateData.subTypes,
                        [subTypeKey]: {
                            ...templateData.subTypes[subTypeKey],
                            copy: {
                                ...templateData.subTypes[subTypeKey].copy,
                                value: copy,
                            },
                            landPageUrl: {
                                ...templateData.subTypes[subTypeKey].landPageUrl,
                                value: landPageUrl,
                            },
                            logoImageUrl: {
                                ...templateData.subTypes[subTypeKey].logoImageUrl,
                                value: logoImageUrl,
                            },
                            title: {
                                ...templateData.subTypes[subTypeKey].title,
                                value: title,
                            },
                        },
                    },
                },
            };
        }

        case 'SUCCESS_FILL_FROM_LINK': {
            const {subTypeKey, title, featuredImage, excerpt, wordpressLink} =
                action;

            const {templateData} = state;

            return {
                ...state,
                templateData: {
                    ...templateData,
                    subTypes: {
                        ...templateData.subTypes,
                        [subTypeKey]: {
                            ...templateData.subTypes[subTypeKey],
                            copy: {
                                ...templateData.subTypes[subTypeKey].copy,
                                value: excerpt,
                            },
                            landPageUrl: {
                                ...templateData.subTypes[subTypeKey].landPageUrl,
                                value: wordpressLink,
                            },
                            logoImageUrl: {
                                ...templateData.subTypes[subTypeKey].logoImageUrl,
                                value: featuredImage,
                            },
                            title: {
                                ...templateData.subTypes[subTypeKey].title,
                                value: title,
                            },
                        },
                    },
                },
            };
        }

        case 'UPDATE_ALL_SPONSORS': {
            const {sponsor, salesforceId} = action;
            const {templateData} = state;

            const {subTypes} = templateData;
            const subTypeKeys = Object.keys(subTypes);
            for (let i = 0; i < subTypeKeys.length; i = i + 1) {
                const subTypeKey = subTypeKeys[i];
                const subType = subTypes[subTypeKey];
                const {rootType} = subType;
                if (!rootType.includes('sponsor')) continue;
                if (
                    !subType.hasOwnProperty('companyName') ||
                    !subType.hasOwnProperty('salesforceId')
                )
                    continue;
                subType.companyName.value = sponsor;
                subType.salesforceId.value = salesforceId;
            }

            return {
                ...state,
                templateData: {
                    ...state.templateData,
                    subTypes,
                },
            };
        }

        case 'CHANGE_ELEMENT_DIRECTION': {
            const {templateData} = state;
            const {item} = action;
            const [subTypeKey, fieldTypeKey] = item.split('.');
            return {
                ...state,
                templateData: {
                    ...templateData,
                    subTypes: {
                        ...templateData.subTypes,
                        [subTypeKey]: {
                            ...templateData.subTypes[subTypeKey],
                            [fieldTypeKey]: {
                                ...templateData.subTypes[subTypeKey][fieldTypeKey],
                                changeDirection:
                                    !templateData.subTypes[subTypeKey][fieldTypeKey]
                                        .changeDirection,
                            },
                        },
                    },
                },
            };
        }

        case 'CHANGE_PREVIEW_TEXT': {
            const {previewText} = action;
            return {
                ...state,
                previewText,
            };
        }

        case 'CHANGE_MAILING_LIST': {
            const {mailingListId} = action;
            return {
                ...state,
                mailingListId,
            };
        }

        case 'SUCCESS_SET_MAILING_LISTS': {
            const {mailingLists} = action;
            return {
                ...state,
                mailingLists,
            };
        }

        case 'FAIL_SET_MAILING_LISTS': {
            const {message} = action;
            return {
                ...state,
                mailingLists: [],
                message,
            };
        }

        case 'SUCCESS_UPDATE_FOOTER': {
            let {footers, footerId} = action;

            if (!footerId && footers.length > 0) footerId = footers[0].id;
            if (!footerId) footerId = null;
            return {
                ...state,
                footerId: footerId,
                footers: footers,
            };
        }

        case 'FOOTER_CHANGE': {
            const {footerId} = action;
            return {
                ...state,
                footerId: footerId,
            };
        }

        case 'FAIL_UPDATE_FOOTER': {
            return {
                ...state,
                footer: null,
            };
        }

        default:
            return state;
    }
};

export default NewsletterReducer;
