import {useEffect, useState} from "react";
import Navigation from "../../../components/Navigation";
import {Link} from "react-router-dom";

import List from "../../../components/List";
import {toast} from "react-toastify";
import {initInternalApi} from "../../../newsletter-api/init";

const api = initInternalApi();

type ElementType = {
    type: "select" | "text"
    placeholder?: string
    fieldName: string
    fieldValue: any
    options?: any[]
}

const getFieldValue = (fieldName: string, elements: ElementType[]): any => {
    const element = elements.find((e: any) => e.fieldName === fieldName) as any;
    if (!element) throw new Error(`Element ${fieldName} not found`)
    return element.fieldValue;
}

const Brand = () => {
    const [isFetching, setIsFetching] = useState(false);
    const [brandFilters, setBrandFilters] = useState<any[]>([]);

    const updateBrand = async (id: any) => {
        const brand = brandFilters.find((b: any) => b.id === id) as any;
        if (!brand) return;

        console.log(brand);
        console.log(brand.elements);
        const brandName = getFieldValue('name', brand.elements);
        await api.updateBrand(id, {
            name: brandName,
            code: getFieldValue('pubCode', brand.elements),
            logoUrl: getFieldValue('logoUrl', brand.elements),
            homePageUrl: getFieldValue("homeUrl", brand.elements),
            tagline: getFieldValue('tagline', brand.elements),
            updateProfileLink: getFieldValue("updateProfile", brand.elements),
            postUpBrandId: getFieldValue('postUpBrandId', brand.elements),
            campaignId: getFieldValue('campaignId', brand.elements),
            color: getFieldValue('color', brand.elements),
            glowLogoUrl: getFieldValue('glowLogoUrl', brand.elements),
            darkLogoUrl: getFieldValue('darkLogoUrl', brand.elements),
            secondaryColor: getFieldValue("secondaryColor", brand.elements),
            fromName: getFieldValue("fromName", brand.elements),
        });

        toast.success(`Brand ${brandName} updated successfully`);
    }

    const updateEntry = (brandId: number, fieldName: string, fieldValue: any) => {
        const brand = brandFilters.find((b: any) => b.id === brandId) as any;
        if (!brand) return;

        let fieldValueParsed: string | number | null = null;
        if (fieldValue == null || fieldValue === '') {
            fieldValueParsed = null;
        } else if (isNaN(Number(fieldValue))) {
            fieldValueParsed = fieldValue;
        } else {
            fieldValueParsed = Number(fieldValue);
        }

        const newBrand = {
            ...brand,
            elements: brand.elements.map((e: any) => e.fieldName === fieldName ? {
                ...e,
                fieldValue: fieldValueParsed
            } : e)
        }

        // @ts-ignore
        setBrandFilters(brandFilters.map((b: any) => b.id === brandId ? newBrand : b));
    }

    useEffect(() => {
        setIsFetching(true);
        const d = async () => {
            try {
                const brands = await api.fetchBrandNames();

                const brandStructure = brands.map((b: any) => {
                    const elements: ElementType[] = [
                        {
                            fieldName: "postUpBrandId",
                            fieldValue: b["postUpBrandId"],
                            type: "text",
                            placeholder: "PostUp Brand ID"
                        },
                        {
                            fieldName: "campaignId",
                            fieldValue: b["campaignId"],
                            type: "text",
                            placeholder: "PostUp Campaign ID"
                        },
                        {
                            fieldName: "name",
                            fieldValue: b["name"],
                            type: "text",
                            placeholder: "Name"
                        },
                        {
                            fieldName: "pubCode",
                            fieldValue: b["pubCode"],
                            type: "text",
                            placeholder: "Pub Code"
                        },
                        {
                            fieldName: "logoUrl",
                            fieldValue: b["logoUrl"],
                            type: "text",
                            placeholder: "Logo URL"
                        },
                        {
                            fieldName: "darkLogoUrl",
                            fieldValue: b["darkLogoUrl"],
                            type: "text",
                            placeholder: "Dark Logo URL"
                        },
                        {
                            fieldName: "glowLogoUrl",
                            fieldValue: b["glowLogoUrl"],
                            type: "text",
                            placeholder: "Glow Logo URL"
                        },
                        {
                            fieldName: "homeUrl",
                            fieldValue: b["homeUrl"],
                            type: "text",
                            placeholder: "Website URL"
                        },
                        {
                            fieldName: "tagline",
                            fieldValue: b["tagline"],
                            type: "text",
                            placeholder: "Tagline"
                        },
                        {
                            fieldName: "color",
                            fieldValue: b["color"],
                            type: "text",
                            placeholder: "Color"
                        },
                        {
                            fieldName: "updateProfile",
                            fieldValue: b["updateProfile"],
                            type: "text",
                            placeholder: "Profile Link"
                        },
                        {
                            fieldName: "fromName",
                            fieldValue: b["fromName"],
                            type: "text",
                            placeholder: "From Name"
                        },
                        {
                            fieldName: "secondaryColor",
                            fieldValue: b["secondaryColor"],
                            type: "text",
                            placeholder: "Secondary Color"
                        },
                    ];
                    return {
                        id: b.id,
                        elements: elements
                    }
                })
                setBrandFilters(brandStructure)
            } catch (e) {

            } finally {
                setIsFetching(false)
            }
        }
        d().then();
    }, [])

    if (isFetching) return <div> Loading... </div>

    return <div style={{width: '80%'}}>
        <Navigation/>

        <div>
            <Link to="/internal/brands/create">
                <button>
                    Create Brand
                </button>
            </Link>
        </div>

        <List
            inputs={brandFilters}
            headers={["PostUp Brand ID", "PostUp Campaign ID", "Name", "Pub Code", "Logo URL", "Dark Logo URL", "Glow Logo URL", "Website URL", "Tagline", "Color", "Profile Link", "From Name", "Secondary Color"]}
            updateEntry={updateEntry}
            showHeaders={!isFetching}
            saveItem={updateBrand}
        />
    </div>
}

export default Brand;