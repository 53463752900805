import React from 'react';

const Brands = ({ change, brands, selectedId }) => (
  <div className="form-row">
    <select
      className="form-control"
      onChange={(e) => {
        const { target } = e;
        const { value } = target;
        const brandId = Number(value);

        if (isNaN(brandId)) return;
        change(brandId);
      }}
      value={selectedId}
    >
      <option disabled value={0}>
        Select a brand....
      </option>
      {brands ? (
        brands
          .sort(function (a, b) {
            if (a.name > b.name) return 1;
            if (a.name < b.name) return -1;
            return 0;
          })
          .map((brand) => {
            return (
              <option value={brand.id} key={brand.id} className="form-control">
                {brand.name}
              </option>
            );
          })
      ) : (
        <option> No brands available </option>
      )}
    </select>
  </div>
);

export default Brands;
