import React, { Component } from 'react';
import { Card, CardBody, Collapse } from 'reactstrap';
import SubType from './SubType';

class RootType extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  /**
   * Component handles if it is open or closed, as we dont open or close
   * the state based on whats loaded or if a new newsletter is changed
   * unlike SubTypes, which items that are turned on are saved.
   */
  toggleOpen = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  render() {
    const { id, name, toggleSubTypeOn, changeInput, clickInput, subTypes } =
      this.props;
    return (
      <div className="row" key={id}>
        <div className="col">
          <RootTypeButton name={name} id={id} toggleOpen={this.toggleOpen} />
          <Collapse isOpen={this.state.isOpen}>
            <Card>
              <CardBody>
                {Object.keys(subTypes)
                  .map((subTypeKey) => subTypes[subTypeKey])
                  .sort((a, b) => {
                    if (a.order > b.order) return 1;
                    if (a.order < b.order) return -1;
                    return 0;
                  })
                  .map((subType) => (
                    <SubType
                      subType={subType}
                      toggleSubTypeOn={toggleSubTypeOn}
                      clickInput={clickInput}
                      changeInput={changeInput}
                      key={subType.id}
                    />
                  ))}
              </CardBody>
            </Card>
          </Collapse>
        </div>
      </div>
    );
  }
}

const RootTypeButton = ({ name, id, toggleOpen }) => (
  <button
    className="btn"
    type="button"
    aria-expanded="false"
    name={id}
    onClick={(e) => {
      e.preventDefault();
      toggleOpen();
    }}
  >
    {name}
  </button>
);

export default RootType;
