import Handlebars from 'handlebars/dist/cjs/handlebars'

/**
 * notEmpty checks to see if the field supplied is empty
 * if it is, we do not turn the field on
 * if it is not we return the field on or rather execute the containing context
 */
Handlebars.registerHelper('notEmpty', function notEmpty(field, options) {
  if (field === '') return options.inverse(this);
  return options.fn(this);
});

Handlebars.registerHelper('firstNonNull', function(...args) {
  // Remove the last argument which is Handlebars-specific options object
  args.pop();

  // Find the first non-null value
  for (let value of args) {
    if (value !== null) {
      return value;
    }
  }

  // Return null if all values are null
  return null;
});

Handlebars.registerHelper('allTrue', function allOn(fields, options2) {
  // eslint-disable-next-line prefer-rest-params
  const options = arguments[arguments.length - 1];
  for (let i = 0; i < arguments.length - 1; i += 1) {
    // eslint-disable-next-line prefer-rest-params
    const argument = arguments[i];
    if (!argument) return options.inverse(this);
  }

  return options.fn(this);
})

/**
 * anyNotEmpty returns true if any of the arbitrary arguments supplied are not empty( === "")
 *
 */
Handlebars.registerHelper('anyNotEmpty', function anyNotEmpty() {
  // eslint-disable-next-line prefer-rest-params
  const options = arguments[arguments.length - 1];
  for (let i = 0; i < arguments.length - 1; i += 1) {
    // eslint-disable-next-line prefer-rest-params
    const argument = arguments[i];
    if (argument.trim() !== '') return options.fn(this);
  }

  return options.inverse(this);
});

/**
 * Checks to see if any arbitrary amount of arguments passed
 * have a true value.
 *
 */
Handlebars.registerHelper('anyOn', function anyOn() {
  // eslint-disable-next-line prefer-rest-params
  const options = arguments[arguments.length - 1];
  for (let i = 0; i < arguments.length - 1; i += 1) {
    // eslint-disable-next-line prefer-rest-params
    const argument = arguments[i];
    if (argument) return options.fn(this);
  }

  return options.inverse(this);
});

Handlebars.registerHelper('anyOnSubExpression', function anyOn() {
  // eslint-disable-next-line prefer-rest-params
  // const options = arguments[arguments.length - 1];
  for (let i = 0; i < arguments.length - 1; i += 1) {
    // eslint-disable-next-line prefer-rest-params
    const argument = arguments[i];
    if (argument) return true
  }
  return false;
});

/**
 * Renders unique types based on the field type that has been passed
 * if no field type has been passed then we default to a normal text input
 */
Handlebars.registerHelper('renderType', (field, subTypeId) => {
  const { type } = field;
  const defaultEle = `
                 <input 
                    type="text" 
                    placeholder="${field.name}" 
                    class="form-control field-ele" 
                    data-id="${subTypeId}"
                    data-field-name="${field.key}"
                    value="${field.value}"
                    id="${subTypeId}-${field.key}"
                    data-hook="${field.hook ? field.hook : ''}"   
                />
           `;

  if (type === undefined) return defaultEle;

  switch (type) {
    case 'button': {
      return `
                <button 
                    class="btn btn-primary field-ele"
                    data-id="${subTypeId}"
                    data-hook="${field.hook ? field.hook : ''}"  
                    data-field-name="${field.key}"    
                >        
                    ${field.name}
                </button>
            `;
    }
    case 'checkbox': {
      return `
        <div class="form-check">
            <input 
                type="checkbox"
                class="form-check-input field-ele"
                data-id="${subTypeId}"
                data-hook="${field.hook ? field.hook : ''}"   
                data-field-name="${field.key}"
                id="${subTypeId}-${field.key}"
                ${field.value ? 'checked' : ''}
        />
            <label
                for="${subTypeId}-${field.key}"
                class="form-check-label"
            >
                ${field.name}
            </label>
        </div>
      `;
    }

    case 'textarea': {
      return `<textarea
            class="form-control field-ele"
            data-id="${subTypeId}"
            data-hook="${field.hook ? field.hook : ''}"   
            data-field-name="${field.key}"
            id="${subTypeId}-${field.key}"
        >
        ${field.value}
        </textarea>`;
    }

    default: {
      return defaultEle;
    }
  }
});
