import React from 'react';
import {connect} from 'react-redux';
import FieldType from './FieldType';

class SubType extends React.Component {
    render() {
        const {subType, toggleSubTypeOn, changeInput, clickInput} = this.props;
        const {id, name, order, rootType, isOn, preProcess, ...fields} = subType;

        return (
            <div>
                <SubTypeButton
                    id={id}
                    name={name}
                    isOn={isOn}
                    toggleIsOn={toggleSubTypeOn}
                />
                <div style={isOn ? {} : {display: 'none'}} id={id}>
                    {Object.keys(fields)
                        .map((fieldKey) => {
                            const field = fields[fieldKey];
                            return {
                                ...field,
                                id: `${id}.${fieldKey}`,
                            };
                        })
                        .sort((a, b) => {
                            if (a.order > b.order) return 1;
                            if (a.order < b.order) return -1;
                            return 0;
                        })
                        .map((field) => {
                            const {id, name, value, type, hook} = field;
                            return (
                                <FieldType
                                    key={id}
                                    id={id}
                                    placeholder={name}
                                    value={value}
                                    type={type}
                                    hook={hook}
                                    changeInput={changeInput}
                                    clickInput={clickInput}
                                />
                            );
                        })}
                </div>
            </div>
        );
    }
}

const SubTypeButton = ({id, isOn, name, toggleIsOn}) => (
    <h4>
        <label className="switch">
            <input
                type="checkbox"
                name={id}
                value={isOn}
                onChange={(e) => {
                    const {target} = e;
                    const {name: subTypeId} = target;
                    return toggleIsOn(subTypeId);
                }}
                checked={isOn}
            />
            <span className="slider round"/>
        </label>
        <span>{name}</span>
    </h4>
);

export default connect()(SubType);
