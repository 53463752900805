import React, {useEffect, useState} from 'react';
import {BrowserRouter as Router, Switch, Route, Redirect, useHistory} from 'react-router-dom';

import Generator from './pages/Generator';
import List from './pages/List';
import {Auth0Provider, useAuth0} from "@auth0/auth0-react";
import {browserName, browserVersion} from "react-device-detect";

import Footer from "./pages/internal/footer/index.tsx";
import CreateFooter from "./pages/internal/footer/create";

import Brand from "./pages/internal/brand";
import CreateBrand from "./pages/internal/brand/create";
import Login from "./pages/Login";
import Internal from "./pages/internal";

import {setAuth} from "./newsletter-api/init";
import {Provider} from "react-redux";
import {ToastContainer} from "react-toastify";
import store from './store';
import {auth0Audience, auth0ClientId, auth0Domain} from "./auth0";

// Create a protected route that will redirect to auth0 if user is not logged in
const ProtectedRoute = ({component: Component, ...rest}) => {
    const auth = useAuth0();
    const history = useHistory();
    const [isLoadingInternal, setIsLoadingInternal] = useState(true);

    useEffect(() => {
        const init = async () => {
            try {
                const api = await setAuth(auth)
                const authIsValid = await api.authIsValid();
                if (!authIsValid) history.replace("/login")
            } catch (e) {
                console.error(e);
                history.replace("/login")
            } finally {
                setIsLoadingInternal(false)
            }
        }
        if (auth.isAuthenticated) init()
        else if (!auth.isLoading) setIsLoadingInternal(false)
    }, [auth.isLoading, auth.isAuthenticated])

    if (auth.isLoading) return <div>Loading...</div>;
    if (!auth.isAuthenticated) return <Redirect to="/login"/>;
    if (isLoadingInternal) return <div>Loading...!</div>;
    return <Route {...rest} render={(props) => <Component {...props} />}/>;
}

const App = () => {

    if (browserName !== 'Chrome' && browserName !== 'Firefox' && browserName !== 'Brave') {
        return (
            // center in the middle of the screen
            <div style={{
                position: 'absolute', left: '35%', top: '35%',
                textAlign: 'center'
            }}>
                <h1>Unsupported Browser</h1>
                <p>Sorry, but the Newsletter Generator only supports Chrome, FireFox, and Brave.</p>
                <p>It looks like you are using {browserName} {browserVersion}.</p>
                <></>
            </div>
        );
    }

    return (
        <Provider store={store}>
            <Auth0Provider
                domain={auth0Domain}
                clientId={auth0ClientId}
                authorizationParams={{
                    audience: auth0Audience,
                    redirect_uri: window.location.origin,
                    scope: 'openid profile email'
                }}
                useRefreshTokens={true}
                cacheLocation={'localstorage'}
            >
                <ToastContainer/>

                <Router>
                    <Switch>
                        <ProtectedRoute path="/internal/brands/create" component={CreateBrand}/>
                        <ProtectedRoute path="/internal/footers/create" component={CreateFooter}/>
                        <ProtectedRoute path="/internal/brands" component={Brand}/>
                        <ProtectedRoute path="/internal/footers" component={Footer}/>
                        <ProtectedRoute path="/internal" component={Internal}/>
                        <ProtectedRoute path="/list" component={List}/>
                        <Route path="/login" component={Login}/>
                        <Route path="/version" component={() => {
                            return (<div>3.0.1</div>)
                        }}/>
                        <ProtectedRoute exact path="/" component={Generator}/>
                    </Switch>
                </Router>
            </Auth0Provider>
        </Provider>

    );
}

export default App;