import React from 'react';

import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Button from './Button';

const SendTestModal = ({
  modalOpen,
  toggleModal,
  testEmailAddress,
  changeTestEmailAddress,
  testSubject,
  changeTestSubject,
  sendTestEmail,
  sending,
  message,
  exitModal,
}) => {
  let body = null;
  let footer = null;

  const exitButton = (
    <Button className="btn btn-danger" onClick={exitModal} text="Exit" />
  );

  const sendButton = (
    <Button
      className="btn btn-primary"
      onClick={sendTestEmail}
      text="Send Email"
    />
  );

  if (sending) {
    body = <div>Sending Newsletter ... </div>;
    footer = <></>;
  } else if (message !== '') {
    body = <div>{message}</div>;
    footer = exitButton;
  } else {
    body = (
      <form action="">
        <div className="form-row">
          <input
            type="text"
            className="form-control"
            value={testEmailAddress}
            onChange={changeTestEmailAddress}
            placeholder="Email Address"
          />
        </div>

        <div className="form-row">
          <input
            type="text"
            className="form-control"
            value={testSubject}
            onChange={changeTestSubject}
            placeholder="Email Subject"
          />
        </div>
      </form>
    );
    footer = (
      <>
        {sendButton}
        {exitButton}
      </>
    );
  }
  return (
    <Modal isOpen={modalOpen} toggle={toggleModal}>
      <ModalHeader toggle={toggleModal}>
        You're Now Sending a Test Email
      </ModalHeader>
      <ModalBody>{body}</ModalBody>
      <ModalFooter>{footer}</ModalFooter>
    </Modal>
  );
};

export default SendTestModal;
