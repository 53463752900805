import React from "react";

const Footers = ({change, footers, selectedId}) => {
    return (
        <div className="form-row">
            <select
                className="form-control"
                onChange={(e) => {
                    const { target } = e;
                    const { value } = target;
                    const footerId = Number(value);

                    if (isNaN(footerId)) return;
                    change(footerId);
                }}
                value={selectedId ? selectedId : 0}
            >
                <option disabled value={0}>
                    Select A Footer
                </option>
                {footers ? (
                    footers
                        .sort(function (a, b) {
                            if (a.name > b.name) return 1;
                            if (a.name < b.name) return -1;
                            return 0;
                        })
                        .map((footer) => {
                            return (
                                <option value={footer.id} key={footer.id} className="form-control">
                                    {footer.name}
                                </option>
                            );
                        })
                ) : (
                    <option> No Footers Found </option>
                )}
            </select>
        </div>
    )
}

export default Footers