import React, {useEffect} from 'react';
import {Table} from 'reactstrap';
import './List.css';
import {useState} from "react";
import {initInternalApi} from "../newsletter-api/init";

const List = () => {
    const [data, setData] = useState(null);
    const [itemsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalItems, setTotalItems] = useState(0);

    const changePage = async (e, pagePathStr) => {
        e.preventDefault();
        let pagePathNum = 0;

        if (pagePathStr === 'prev') {
            pagePathNum = -1;
        } else if (pagePathStr === 'next') {
            pagePathNum = 1;
        } else {
            return;
        }

        const page = currentPage + pagePathNum;

        if (page * itemsPerPage > totalItems + 9 || page <= 0) return;

        try {
            const api = initInternalApi();
            const reqPage = page - 1;
            const {newsletters, totalNewsletters} = await api.fetchNewsletters(reqPage);
            setCurrentPage(page)
            setData(newsletters);
            setTotalItems(totalNewsletters);

        } catch (error) {
            console.log(error);
        }
    };

    const redirect = (e, id) => {
        e.preventDefault();
        window.location = `${window.location.origin}?id=${id}`;
    };

    useEffect(() => {
        const fetchData = async () => {
            const api = await initInternalApi();
            const {newsletters, totalNewsletters}  = await api.fetchNewsletters(0);
            setData(newsletters);
            setTotalItems(totalNewsletters);
        }
        fetchData().then();
    }, []);

    const totalPages = Math.ceil(totalItems / itemsPerPage);
    if (data === null) return <h1> Cant Find Newsletters</h1>;

    return (
        <div>
            <Table hover bordered>
                <thead>
                <tr>
                    <th> Newsletter ID</th>
                    <th> User</th>
                    <th> Brand</th>
                    <th> Tagline</th>
                    <th> Release Date</th>
                </tr>
                </thead>
                <tbody>
                {data.map((data) => {
                    const releaseDate = new Date(data.releaseDate);
                    const day = releaseDate.getUTCDate();
                    const month = releaseDate.getUTCMonth() + 1;
                    const year = releaseDate.getUTCFullYear();
                    const stringDate = `${month} / ${day} / ${year}`;

                    return (
                        <tr
                            onClick={(e) => redirect(e, data.id)}
                            key={data.id}
                            className="link"
                        >
                            <th scope="row">{data.id}</th>
                            <td>{data.createdBy}</td>
                            <td>{data.brand}</td>
                            <td>{data.tagline}</td>
                            <td>{stringDate}</td>
                        </tr>
                    );
                })}
                </tbody>
            </Table>
            <div
                style={{
                    textAlign: 'center',
                }}
            >
                <button
                    className="btn btn-primary"
                    onClick={(e) => changePage(e, 'prev')}
                >
                    Previous Page
                </button>
                <span>
            {' '}
                    {currentPage} / {totalPages}{' '}
          </span>
                <button
                    className="btn btn-primary"
                    onClick={(e) => changePage(e, 'next')}
                >
                    Next Page
                </button>
            </div>
            <br/>
            <div
                style={{
                    textAlign: 'center',
                }}
            >
                <a href="https://nlgen.wtwhmedia.com/">
                    <button className="btn btn-primary">
                        Back To Newsletter Generator
                    </button>
                </a>
                <span> </span>
                <a href="https://resources.wtwhmedia.com/admin">
                    <button className="btn btn-primary">Back To Resources</button>
                </a>
            </div>
        </div>
    )
}

export default List;
