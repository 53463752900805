import React from 'react';

const MailingLists = ({ change, mailingLists, brandId, selectedId }) => {
  // Set the default option value based on if brand is chosen,
  // or if there are truly no available mailing lists for this brand
  let option = null;

  if (brandId === 0) {
    option = <option value={0}> Please pick a brand... </option>;
  } else if (
    (brandId > 0 && mailingLists === null) ||
    mailingLists.length <= 0
  ) {
    option = (
      <option value={0}> No mailing lists available for this brand... </option>
    );
  } else {
    option = <option value={0}> Pick a mailing list... </option>;
  }

  return (
    <div className="form-row">
      <select
        name=""
        id=""
        className="form-control"
        onChange={(e) => {
          const { target } = e;
          const { value } = target;
          change(value);
        }}
        value={selectedId}
      >
        {option}
        {mailingLists ? (
          mailingLists
            .sort(function (a, b) {
              if (a.name > b.name) return 1;
              if (a.name < b.name) return -1;
              return 0;
            })
            .map((mailingList) => (
              <option
                value={mailingList.listId}
                key={mailingList.listId}
                className="form-control"
              >
                {mailingList.name}
              </option>
            ))
        ) : (
          <></>
        )}
      </select>
    </div>
  );
};

export default MailingLists;
