import React from 'react';

import {Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import Button from './Button';

const SaveModal = ({
                       newsletterId,
                       exitModal,
                       modalOpen,
                       toggleModal,
                       saving,
                       message,
                       save,
                       update,
                   }) => {

    const modalHandler = () => {
        toggleModal();
        exitModal();
    }

    const saveButton = (
        <Button onClick={save} className="btn btn-primary" text="Save"/>
    );
    const exitModalButton = (
        <Button onClick={modalHandler} className="btn btn-danger" text="Exit"/>
    );

    let body = null;
    let footer = null;
    if (saving) {
        body = <div>Saving Newsletter...</div>;
        footer = <></>;
    } else if (message !== '') {
        body = <div> {message} </div>;
        footer = <>{exitModalButton}</>;
    } else if (newsletterId > 0) {
        body = (
            <div>
                To save your current changes to the existing newsletter, choose "Save". This will overwrite the old
                newsletter. If you wish you create a new newsletter from your changes, choose "Save As".
            </div>
        );
        footer = (
            <>
                <Button onClick={update} className="btn btn-success" text="Save"/>
                <Button onClick={save} className="btn btn-primary" text="Save As"/>
                {exitModalButton}
            </>
        );
    } else {
        body = <div>Save a New Newsletter.</div>;
        footer = (
            <>
                {saveButton}
                {exitModalButton}
            </>
        );
    }

    return (
        <Modal isOpen={modalOpen} toggle={modalHandler}>
            <ModalHeader toggle={modalHandler}>
                You Are Saving a Newsletter
            </ModalHeader>
            <ModalBody>{body}</ModalBody>
            <ModalFooter>{footer}</ModalFooter>
        </Modal>
    );
};

export default SaveModal;
