import React from 'react';
import { ChromePicker } from 'react-color';

const Color = ({ value, changeInput, id }) => {
  // Default is #fff(white)
  if (value === '') value = '#fff';

  return (
    <ChromePicker
      color={value}
      onChangeComplete={(color) => {
        return changeInput(id, color.hex, '');
      }}
    />
  );
};

export default Color;
