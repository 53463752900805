import React from 'react';

const PreviousNewsletter = ({id, change, click}) => (
    <>
        <div className="form-row">
            <input
                type="text"
                className="form-control"
                placeholder="Fetch previous newsletter by id"
                value={id === 0 ? '' : id}
                onChange={(e) => {
                    const {target} = e;
                    const {value} = target;
                    change(value);
                }}
            />
        </div>
        <div className="form-row">
            <button
                type="button"
                className="btn btn-primary"
                onClick={(e) => {
                    e.preventDefault();
                    click(id);
                }}
            >
                Retrieve Newsletter Submission
            </button>
        </div>
    </>
);

export default PreviousNewsletter;
