const initState = {
    modalOpen: false,
    saving: false,
    subject: '',
    message: '',
    title: '',
};

const SaveToPostUpReducer = (state = initState, action) => {
    switch (action.type) {
        case 'TOGGLE_SAVE_POST_UP_MODAL':
            return {
                ...state,
                modalOpen: !state.modalOpen,
            };

        case 'CHANGE_POST_UP_SUBJECT':
            return {
                ...state,
                subject: action.subject,
            };

        case 'CHANGE_POST_UP_TITLE':
            return {
                ...state,
                title: action.title,
            };

        case 'SAVE_TO_POST_UP':
            return {
                ...state,
                saving: true,
                message: '',
            };

        case 'SUCCESS_SAVE_TO_POST_UP':
        case 'FAIL_SAVE_TO_POST_UP':
            return {
                ...state,
                message: action.message,
                saving: false,
            };

        case 'EXIT_SAVE_POST_UP_MODAL':
            return {
                ...state,
                modalOpen: false,
                subject: '',
                message: '',
                saving: false,
                title: '',
            };
        default:
            return state;
    }
};

export default SaveToPostUpReducer;
