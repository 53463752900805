import Handlebars from 'handlebars/dist/cjs/handlebars'

import {mapCurrentContextToNewContext} from '../services/editor-service';
import {initInternalApi} from "../newsletter-api/init";

// Notes:
// An action should describe how it effects(noun), not what it affects(verb)
// That is, it should describe a change that happened in your system
// user logged on, some data was loaded, an api call started, an error encountered, etc...
// REQUEST_POST -> REQUESTING_POSTS, RECEIVE_POSTS -> RECEIVED_POSTS
// Better:
// LOAD_POST_REQUEST
// LOAD_POST_FAILURE
// LOAD_POST_SUCCESS
// Complex actions that have multiple actions that affect your state should be present-tense verbs

export const changeFooter = (footerId) => {
    return dispatch => {
        dispatch({
            type: 'FOOTER_CHANGE',
            footerId,
        })
    }
}

export const changeBrand = (brandId) => {
    return (dispatch, getState) => {
        const {brands, templateId} = getState().NewsletterReducer;
        const brand = brands.find((brand) => brand.id === brandId);

        dispatch(setMailingLists(brand.postUpBrandId));
        dispatch(updateFooter(brandId, templateId));

        dispatch({
            type: 'BRAND_CHANGE',
            brandId,
        });
    };
};

export const updateTagline = (tagline) => ({
    type: 'CHANGE_TAGLINE',
    tagline,
});

export const updateFooter = (brandId, templateId, footerId) => {
    return async (dispatch) => {
        const api = initInternalApi();
        try {
            const responses = await api.fetchFooter(brandId, templateId);
            dispatch({
                type: 'SUCCESS_UPDATE_FOOTER',
                footers: responses,
                footerId
            });
        } catch (error) {
            console.log(error);
            dispatch({
                type: 'FAIL_UPDATE_FOOTER',
            });
        }
    };
};

export const changePreviewText = (previewText) => ({
    type: 'CHANGE_PREVIEW_TEXT',
    previewText,
});

export const changeMailingList = (mailingListId) => ({
    type: 'CHANGE_MAILING_LIST',
    mailingListId,
});

export const setMailingLists = (postUpBrandId) => {
    return async (dispatch) => {
        const api = initInternalApi();
        try {
            const mailingLists = await api.fetchEmailLists(postUpBrandId);
            if ('error' in mailingLists) {
                dispatch({
                    type: "FAIL_SET_MAILING_LISTS",
                    message: "Failed to setup mailing list"
                })
                return
            }
            dispatch({
                type: 'SUCCESS_SET_MAILING_LISTS',
                mailingLists,
            });
        } catch (error) {
            console.log(error);
            dispatch({
                type: 'FAIL_SET_MAILING_LISTS',
                message: 'mailing list failed to set',
            });
        }
    };
};

export const toggleDarkMode = () => {
    return {
        type: "TOGGLE_DARK_MODE",
    }
}

export const changeReleaseDate = (releaseDateFull) => {
    const monthNames = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ];

    const releaseDate = `${
        monthNames[releaseDateFull.getMonth()]
    } ${releaseDateFull.getDate()}, ${releaseDateFull.getFullYear()}`;

    return {
        type: 'CHANGE_RELEASE_DATE',
        releaseDateFull,
        releaseDate,
    };
};

export const fetchPrevNewsletter = (prevNewsletterId) => {
    return async (dispatch, getState) => {
        if (prevNewsletterId <= 0) return;
        const api = initInternalApi();

        try {
            const response = await api.fetchNewsletter(prevNewsletterId);

            const url = window.location.origin + window.location.pathname;
            window.history.pushState(
                {},
                `Newsletter ${prevNewsletterId}`,
                `${url}?id=${prevNewsletterId}`,
            );
            const {brandId, context, releaseDate, tagline, templateId, footerId} = response;

            const {templates, brands} = getState().NewsletterReducer;

            const template = templates.find((template) => template.id === templateId);
            const {header, file, css} = await api.fetchTemplate(
                templateId,
                template.fileLocation,
            );

            const brand = brands.find((brand) => brand.id === brandId);

            dispatch(setMailingLists(brand.postUpBrandId));
            dispatch(updateFooter(brandId, templateId, footerId));

            const hbsTmplFunc = Handlebars.compile(file);
            const hbsTmplHeaderFunc = Handlebars.compile(header);

            const releaseDateFull = new Date(releaseDate);
            dispatch({
                type: 'SUCCESS_PREV_NEWSLETTER',
                tagline: tagline,
                templateStyle: css,
                templateData: context,
                templateId,
                brandId,
                prevNewsletterId,
                releaseDate,
                releaseDateFull,
                hbsTmplHeaderFunc,
                hbsTmplFunc,
            });
        } catch (error) {
            // TODO: CANT DO THIS WITH THUNKS
            console.log(error);
            dispatch({
                type: 'FAIL_PREV_NEWSLETTER',
            });
        }
    };
};

export const prevNewsletterIdChange = (prevNewsletterId) => {
    const pattern = /^(\s*|\d+)$/;
    const t = pattern.test(prevNewsletterId);

    return (dispatch) => {
        if (t) {
            dispatch({
                type: 'PREV_NEWSLETTER_ID_CHANGE',
                prevNewsletterId,
            });
        }
    };
};

export const templateChange = (template, curTmplId, curContext) => {
    return async (dispatch, getState) => {
        const {brandId} = getState().NewsletterReducer;

        const api = initInternalApi();
        const {id, fileLocation} = template;

        let {header, file, context, css} = await api.fetchTemplate(
            id,
            fileLocation,
        );

        const hbsTmplFunc = Handlebars.compile(file);
        const hbsTmplHeaderFunc = Handlebars.compile(header);
        if (curTmplId > 0) {
            context = mapCurrentContextToNewContext(context, curContext);
        }

        dispatch(updateFooter(brandId, id));

        dispatch({
            type: 'TEMPLATE_CHANGE',
            templateId: id,
            hbsTmplFunc,
            hbsTmplHeaderFunc,
            templateStyle: css,
            templateData: context,
        });
    };
};

export const editorInputChange = (fieldTypeId, value) => {
    const [subTypeKey, fieldTypeKey] = fieldTypeId.split('.');

    return {
        type: 'EDITOR_INPUT_CHANGE',
        subTypeKey,
        fieldTypeKey,
        value,
    };
};

export const toggleSubTypeOpen = (subTypeId) => {
    return {
        type: 'TOGGLE_SUB_TYPE_OPEN',
        subTypeKey: subTypeId,
    };
};
