import {initInternalApi} from "../newsletter-api/init";

export const toggleSaveToPostUpModal = () => ({
    type: 'TOGGLE_SAVE_POST_UP_MODAL',
});

export const exitSaveToPostUpModal = () => ({
    type: 'EXIT_SAVE_POST_UP_MODAL',
});

export const changePostUpSubject = (subject) => ({
    type: 'CHANGE_POST_UP_SUBJECT',
    subject,
});

export const changePostUpTitle = (title) => ({
    type: 'CHANGE_POST_UP_TITLE',
    title,
});

export const saveToPostUp = (
    subject,
    brandId,
    listId,
    releaseDate,
    htmlBody,
    title,
) => {
    return async (dispatch) => {
        dispatch({
            type: 'SAVE_TO_POST_UP',
        });
        const api = initInternalApi();
        try {
            const listIdInt = Number(listId);
            const brandIdInt = Number(brandId);
            const response = await api.scheduleEmail(
                subject,
                brandIdInt,
                listIdInt,
                releaseDate,
                htmlBody,
                title
            );

            if ("error" in response) {
                dispatch({
                    type: 'FAIL_SAVE_TO_POST_UP',
                    message: 'failed to save the newsletter to postup, ' + response.error.description,
                });
                return
            }

            dispatch({
                type: 'SUCCESS_SAVE_TO_POST_UP',
                message: 'successfully saved messaged to post up',
            });
        } catch (error) {
            console.log(error, "ERROR");
            dispatch({
                type: 'FAIL_SAVE_TO_POST_UP',
                message: 'failed to save the newsletter to postup',
            });
        }
    };
};
