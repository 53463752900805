import {initInternalApi} from "../newsletter-api/init";

export const toggleSaveModal = () => ({
    type: 'TOGGLE_SAVE_MODAL',
});

export const exitSaveModal = () => ({
    type: 'EXIT_SAVE_MODAL',
});

export const updateNewsletter = (
    releaseDate,
    brandId,
    templateId,
    tagline,
    context,
    newsletterId,
    footerId
) => {
    return async (dispatch) => {
        dispatch({
            type: 'UPDATING_NEWSLETTER',
        });

        const api = initInternalApi();
        try {
            await api.updateNewsletter(
                releaseDate,
                brandId,
                templateId,
                tagline,
                newsletterId,
                context,
                footerId,
            );
            dispatch({
                type: 'SUCCESS_UPDATING_NEWSLETTER',
                message: 'Successfully updated the newsletter',
            });
        } catch (error) {
            console.log(error);
            dispatch({
                type: 'FAIL_UPDATING_NEWSLETTER',
                message: 'failed to update newsletter',
            });
        }
    };
};

export const saveNewsletter = (
    releaseDate,
    brandId,
    templateId,
    tagline,
    context,
    footerId,
) => {
    return async (dispatch) => {
        dispatch({
            type: 'SAVING_NEWSLETTER',
        });

        const api = initInternalApi();
        try {
            const response = await api.createNewsletter(
                releaseDate,
                brandId,
                templateId,
                tagline,
                context,
                footerId,
            );
            const {newsletterId} = response;
            dispatch({
                type: 'SUCCESS_SAVING_NEWSLETTER',
                message: `Successfully created a new newsletter with id ${response.newsletterId}`,
                newsletterId,
            });
        } catch (error) {
            console.log(error);
            dispatch({
                type: 'FAIL_SAVING_NEWSLETTER',
                message: 'failed to save newsletter',
            });
        }
    };
};
