/**
 * If we already have an existing newsletter that is being created
 * and we change templates, we want to take the current context(i.e., data)
 * and re-map it to the new context that we pulled
 * via the context(ie., the new config file)
 *
 * @param newContext
 * @param oldContext
 * @returns {*}
 */
export const mapCurrentContextToNewContext = (newContext, oldContext) => {
  const { subTypes: oldSubTypes } = oldContext;
  const { subTypes: newSubTypes } = newContext;

  const oldSubTypeKeys = Object.keys(oldSubTypes);

  for (let i = 0; i < oldSubTypeKeys.length; i = i + 1) {
    const oldSubTypeKey = oldSubTypeKeys[i];
    const oldSubType = oldSubTypes[oldSubTypeKey];

    // Individual SubTypes
    if (newSubTypes.hasOwnProperty(oldSubTypeKey)) {
      const newSubType = newSubTypes[oldSubTypeKey];
      newSubType.isOn = oldSubType.isOn;

      // Old SubType Key->Values that are objects are fields ( as of 11/5/19 )
      const oldSubTypeKeys = Object.keys(oldSubType);
      for (let j = 0; j < oldSubTypeKeys.length; j = j + 1) {
        const oldSubTypeKey = oldSubTypeKeys[j];
        const oldFieldType = oldSubType[oldSubTypeKey];
        if (typeof oldFieldType !== 'object') continue;
        if (!newSubType.hasOwnProperty(oldSubTypeKey)) continue;
        newSubType[oldSubTypeKey].value = oldFieldType.value;
      }
    }
  }
  return newContext;
};
