import "./darkmode.css";
import {ChangeEventHandler} from "react";

type Props = {
    toggleDarkMode: ChangeEventHandler,
    darkMode: boolean
}

const DarkMode = ({toggleDarkMode, darkMode}: Props) => {
    return (
        <div className="toggle-theme-wrapper">
            <div>
                <span className="dark-img"><img width="200px" height="auto" alt="" src="https://images.designworldonline.com/newsletters/images/7ce4e6ff2f30deb04199954a5cf51c4d.png" /></span>
                <span className="light-img"><img width="200px" height="auto" alt="" src="https://images.designworldonline.com/newsletters/images/9527b80832ef775b7c2fd4440d88cad0.png" /></span>
            </div>
            <div>
                <span>☼</span>
                <label className="toggle-theme" htmlFor="checkbox">
                    <input
                        type="checkbox"
                        id="checkbox"
                        onChange={toggleDarkMode}
                        defaultChecked={darkMode}
                    />
                    <div className="theme-slider round"></div>
                </label>
                <span>☾</span>
            </div>
        </div>
    );
};

export default DarkMode;