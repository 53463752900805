import React, {PureComponent} from 'react';
import {connect} from 'react-redux';

class Newsletter extends PureComponent {
    componentDidUpdate(prevProps, prevState, snapshot) {
        let {
            hbsTmplFunc,
            hbsTmplHeaderFunc,
            templateData,
            templateStyle,
            brand,
            tagline,
            releaseDate,
            footers,
            footerId,
            darkMode
        } = this.props;

        if (hbsTmplFunc === null) return;

        const iFrame = this.refs.newsletterIFrame;
        const iFrameDoc = iFrame.contentDocument;

        const headers = hbsTmplHeaderFunc();
        const footer = footers.find(f => f.id === footerId)

        templateData = {
            ...templateData,
            brand: {
                color: brand.color,
                secondaryColor: brand.secondaryColor,
                darkImageUrl: brand.darkLogoUrl,
                glowLogoUrl: brand.glowLogoUrl,
                imageUrl: brand.logoUrl,
                landingUrl: brand.homeUrl,
                alt: brand.name,
                tagline: tagline,
                updateProfile: brand.updateProfile,
            },
            releaseDate,
            footer: {
                editorName: footer?.editorName,
                editorEmail: footer?.editorEmail,
                mediaGuideUrl: footer?.mediaGuideUrl,
                imageUrl: footer?.imageUrl,
                landingUrl: footer?.landingUrl,
                subscriptionUrl: footer?.subscriptionUrl,
            },
        };

        const osDarkMode =
            window.matchMedia &&
            window.matchMedia("(prefers-color-scheme: dark)").matches;

        // If the current theme is not dark mode, but the OS is dark mode, will bleed through to newsletter, so need to remove dark mode in newsletter
        if (!darkMode && osDarkMode) templateStyle = templateStyle.replace("@media (prefers-color-scheme: dark)", "@media (prefers-color-scheme: d)")

        // if current theme is dark mode but os is in light mode, then need to remove dark mode from newsletter
        // this is because the newsletter relies on the system to determine dark mode, rather than using data-theme like the editor
        // so, we fake its darkmode by swapping the theme to "light" which is really darkmode
        if (darkMode && !osDarkMode) templateStyle = templateStyle.replace("@media (prefers-color-scheme: dark)", "@media (prefers-color-scheme: light)");

        const body = hbsTmplFunc(templateData);
        iFrameDoc.head.innerHTML = `${headers}<style>${templateStyle}</style>`;
        iFrameDoc.body.innerHTML = body;
    }


    render() {
        if (this.props.hbsTmplFunc === null) return <div id="newsletter">Please select a template or enter a Newsletter
            ID to begin.</div>
        return (
            <>
                <iframe
                    id="newsletter"
                    ref="newsletterIFrame"
                    title="rendered-newsletter"
                />
            </>
        );
    }
}

const mapStateToProps = (state) => {
    const {NewsletterReducer} = state;
    const {brandId, brands} = NewsletterReducer;
    let brand = {};
    if (brandId > 0) brand = brands.find((brand) => brand.id === brandId);

    return {
        ...NewsletterReducer,
        brand,
    };
};

export default connect(mapStateToProps)(Newsletter);
