import {Auth0ContextInterface} from "@auth0/auth0-react";

const headers = {
    'Content-Type': 'application/json;charset=utf-8',
    'Accept': 'application/json;charset=utf-8'
};

const basePath = process.env.REACT_APP_API_PATH;
const localPath = process.env.REACT_APP_ASSEST_PATH;
const localTmpls = process.env.REACT_APP_LOCAL_TEMPLATE_NAMES;

export default class NewsletterApi {
    private _authorizationKey: string | null = null;
    private _userEmail: string | null = null;
    private auth: Auth0ContextInterface | null = null;
    private authInternal: NodeJS.Timer | null = null;

    public setAuth = async (auth: Auth0ContextInterface) => {
        this.auth = auth;
        const authToken = await auth.getAccessTokenSilently()
        const c = await auth.getIdTokenClaims()
        if (c) {
            this.setAuthKey(authToken)
            this.setUser(c.nickname || c.name || "")
        }

        this.authInternal = setInterval(async () => {
            const authToken = await auth.getAccessTokenSilently();
            const c = await auth.getIdTokenClaims()
            if (c) {
                this.setAuthKey(authToken)
                this.setUser(c.nickname || c.name || "")
            }
        }, 600000)
    }

    setUser = (key: string) => {
        this._userEmail = key;
    }

    public setAuthKey = (key: string) => {
        this._authorizationKey = key;
    }

    public authIsValid = async (): Promise<boolean> => {
        const brandNames = await this.fetchBrandNames()
        return brandNames !== null
    }

    async makeRequest(route: string, method: string = 'GET', body?: any, queryParams?: any, retry: boolean = false) {
        if (this._authorizationKey) headers['Authorization'] = `Bearer ${this._authorizationKey}`;
        const init = {method, headers};
        if (body) init['body'] = JSON.stringify(body);

        if (queryParams) {
            const queryString = new URLSearchParams(queryParams).toString();
            route = `${route}?${queryString}`
        }

        const response = await fetch(route, init);
        if (response.status !== 401) return await response.json();
        if (!this._authorizationKey || !this.auth || retry) return null

        const authToken = await this.auth.getAccessTokenSilently()
        const c = await this.auth.getIdTokenClaims()
        if (!c) return null;

        this.setAuthKey(authToken)
        this.setUser(c.nickname || c.name || "")
        return this.makeRequest(route, method, body, queryParams, true)
    }

    /**
     * Send the current newsletter to our email provider for later publishing
     * This does not set the actual release date, you must check within the
     * email provider service to release the newsletter.
     *
     * @param brandId
     * @param listId
     * @param sendDate
     * @param subject
     * @param htmlDoc
     * @returns {Promise<*>}
     */
    scheduleEmail = async (subject, brandId, listId, sendDate, htmlDoc, title) => {
        return await this.makeRequest(`${basePath}/e-marketing/schedule`, 'POST', {
            brandId,
            listId,
            sendDate,
            subject,
            htmlBody: htmlDoc,
            user: this._userEmail,
            title
        });
    };

    /**
     * Takes the current newsletter generated and sends a test email
     * to the email address that is provided.
     *
     * @param toEmail
     * @param subject
     * @param htmlDoc
     * @returns {Promise<*>}
     */
    sendTestEmail = async (toEmail, subject, htmlDoc) => {
        return await this.makeRequest(`${basePath}/e-marketing/test`, 'POST', {
            subject,
            toEmail,
            htmlBody: htmlDoc,
            user: this._userEmail
        });
    };

    /**
     * Fetches the available mailing list for an email provider based on the
     * brand id for the mailing list. This is not the brand id we use internally
     * when passing brands back and fourth.
     *
     * @param mailingBrandId
     * @returns {Promise<*>}
     */
    fetchEmailLists = async (mailingBrandId) => await this.makeRequest(`${basePath}/e-marketing/list?mailing_brand_id=${mailingBrandId}`);
    preScheduledEmailCheck = async (subject, htmlDoc) => {
        return await this.makeRequest(`${basePath}/e-marketing/predeployment-check`, 'POST', {
            subject,
            html: htmlDoc
        });
    }
    fetchByYouTubeLink = async (link) => {
        const encodedURL = encodeURIComponent(link);
        return await this.makeRequest(`${basePath}/external-content/youtube?url=${encodedURL}`, 'GET');
    };
    minifyHtml = async (html: string) => await this.makeRequest(`${basePath}/external-content/minify`, 'POST', {
        html: html
    });

    fetchPostByLink = async (link) => await this.makeRequest(`${basePath}/available-media/by-link?link=${link}`)
    fetchWebinars = async (pubCode) => await this.makeRequest(`${basePath}/available-media/webinars?pubcode=${pubCode}`, 'GET');
    fetchPodcasts = async (pubCode) => await this.makeRequest(`${basePath}/external-content/podcast`, 'GET');

    /**
     * Fetches list of available brand names with minimal
     * associated metadata
     *
     * @returns {Promise<*>}
     */
    fetchBrandNames = async (): Promise<any[]> => {
        return await this.makeRequest(`${basePath}/brands`, 'GET')
    }

    /**
     * Fetches a single brand object metadata
     *
     * @param brandId
     * @returns {Promise<*>}
     */
    fetchBrand = async (brandId) => await this.makeRequest(`${basePath}/brands/${brandId}`, 'GET')

    fetchFooter = async (brandId, templateId) => {
        let url = `${basePath}/brands/footer?brandId=${brandId}`
        if (templateId) url += `&templateId=${templateId}`;
        return await this.makeRequest(url);
    }

    getFooters = async (brandId, editorId, templateId) => {
        if (brandId === null && editorId === null && templateId === null) throw new Error('Must provide at least one filter');
        let queryParams: object | null = {};
        if (brandId) queryParams['brandId'] = brandId;
        if (editorId) queryParams['editorId'] = editorId;
        if (templateId) queryParams['templateId'] = templateId;

        if (Object.keys(queryParams).length < 0) queryParams = null;
        return await this.makeRequest(`${basePath}/brands/footers`, 'GET', null, queryParams);
    }

    addFooter = async (footer) => {
        const res = await this.makeRequest(`${basePath}/brands/footers`, 'POST', {
            brandId: parseInt(footer.brandId),
            templateId: parseInt(footer.templateId),
            editorId: parseInt(footer.editorId),
            mediaGuideUrl: footer.mediaGuideUrl,
            imageUrl: footer.imageUrl,
            name: footer.name,
            landingUrl: footer.landingUrl,
            subscriptionUrl: footer.subscriptionUrl
        });
        return res.footerId;
    }

    addBrand = async (brand) => {
        const res = await this.makeRequest(`${basePath}/brands`, 'POST', {
            name: brand.name,
            code: brand.code,
            logoUrl: brand.logoUrl,
            homePageUrl: brand.homePageUrl,
            tagline: brand.tagline,
            updateProfileLink: brand.updateProfileLink,
            postUpBrandId: isNaN(Number(brand.postUpBrandId)) ? null : Number(brand.postUpBrandId),
            campaignId: isNaN(Number(brand.campaignId)) ? null : Number(brand.campaignId),
            color: brand.color,
            glowLogoUrl: brand.glowLogoUrl,
            darkLogoUrl: brand.darkLogoUrl,
            secondaryColor: brand.secondaryColor,
            fromName: brand.fromName,
        });
        return res.id;
    }

    updateBrand = async (id, brand) => {
        await this.makeRequest(`${basePath}/brands/${id}`, 'POST', {
            name: brand.name,
            code: brand.code,
            logoUrl: brand.logoUrl,
            homePageUrl: brand.homePageUrl,
            tagline: brand.tagline,
            updateProfileLink: brand.updateProfileLink,
            postUpBrandId: isNaN(Number(brand.postUpBrandId)) ? null : Number(brand.postUpBrandId),
            campaignId: isNaN(Number(brand.campaignId)) ? null : Number(brand.campaignId),
            color: brand.color,
            glowLogoUrl: brand.glowLogoUrl,
            darkLogoUrl: brand.darkLogoUrl,
            secondaryColor: brand.secondaryColor,
            fromName: brand.fromName,
        });
    }

    deleteFooter = async (footerId) => await this.makeRequest(`${basePath}/brands/footers`, 'DELETE', {
        footerId: footerId
    });

    updateFooter = async (id, footer) => await this.makeRequest(`${basePath}/brands/footers/${id}`, 'PATCH', {
        footerId: id,
        brandId: footer.brandId,
        templateId: footer.templateId,
        editorId: footer.editorId,
        mediaGuideUrl: footer.mediaGuideUrl,
        imageUrl: footer.imageUrl,
        name: footer.name,
        landingUrl: footer.landingUrl,
        subscriptionUrl: footer.subscriptionUrl
    });

    getEditors = async () => await this.makeRequest(`${basePath}/brands/footer/editors`, 'GET');

    fetchNewsletters = async (page) => await this.makeRequest(`${basePath}/newsletters?page=${page}&offset=10`);

    fetchNewsletter = async (newsletterId) => await this.makeRequest(`${basePath}/newsletters/${newsletterId}`);

    /**
     * Request to update the current request. This overwrites the
     * original changes of the request and does not
     * make a new copy. It returns whether or not the record was
     * updated successfully. If the record was updated
     * successfully then an overwrite occurred, otherwise the
     * record will keep its integrity
     *
     * @param releaseDate
     * @param brandId
     * @param templateId
     * @param tagline
     * @param newsletterId
     * @param context
     * @param footerId
     * @returns {Promise<any>}
     */
    updateNewsletter = async (
        releaseDate,
        brandId,
        templateId,
        tagline,
        newsletterId,
        context,
        footerId,
    ) => {
        return await this.makeRequest(`${basePath}/newsletters/${newsletterId}`, 'PATCH', {
            releaseDate,
            brandId,
            templateId,
            tagline,
            context,
            user: this._userEmail,
            footerId
        });
    };

    /**
     * Creates a new instance of a newsletter and returns the newsletterId that has been created
     * @param releaseDate
     * @param brandId
     * @param templateId
     * @param tagline
     * @param context
     * @param footerId
     * @returns {Promise<*>}
     */
    createNewsletter = async (releaseDate, brandId, templateId, tagline, context, footerId) => {
        return await this.makeRequest(`${basePath}/newsletters`, 'POST', {
            releaseDate,
            brandId,
            templateId,
            tagline,
            context,
            user: this._userEmail,
            footerId,
        });
    };

    /**
     * Fetches the available newsletter templates that an end-user can create
     *
     * @returns {Promise<*>}
     */
    fetchTemplates = async () => {
        if (localTmpls) {
            const ltmp = JSON.parse(localTmpls);
            return ltmp.map((l, idx) => {
                return {
                    id: idx,
                    name: l,
                    fileLocation: `${l}/${l}`
                }
            })
        }
        return await this.makeRequest(`${basePath}/templates`);
    };

    /**
     * Here we fetch the
     *    - template json context from via our api
     *    - the template file from the local /templates directory
     *    - the template header file from the /templates directory
     *    - the template css file form the /templates directory
     * The above files that are fetched are in their raw form,
     * unparsed hbs files
     *
     * @param templateId
     * @param fileLocation
     * @returns {Promise<{css: *, file: *, context: *, header: *}>}
     */
    fetchTemplate = async (templateId, fileLocation) => {
        const localHttpPath = `${localPath}/templates/${fileLocation}`;
        let templatePromise;

        if (process.env.REACT_APP_LOCAL_TEMPLATE) {
            templatePromise = fetch(`${localHttpPath}.json`);
        } else {
            templatePromise = this.makeRequest(`${basePath}/templates/${templateId}`, 'GET');
        }

        // Do not mix this up with the /templates endpoint and the
        // /templates directory -- we are not supply the api endpoint here
        // pulling from our local context -- file must have '.hbs' extension
        const tmplFilePromise = fetch(`${localHttpPath}.hbs`);

        // Must have a '-headers.hbs' extension
        // All head and excess items are maintained here
        const tmplHeaderPromise = fetch(`${localHttpPath}-headers.hbs`);

        const tmplCssPromise = fetch(`${localHttpPath}.css`);

        const templateHeaderRes = await tmplHeaderPromise;
        const templateFileRes = await tmplFilePromise;
        const templateDataRes = await templatePromise;
        const templateCssRes = await tmplCssPromise;

        const templateHeader = await templateHeaderRes.text();
        const templateFile = await templateFileRes.text();
        const templateCss = await templateCssRes.text();
        let templateData;
        if (process.env.REACT_APP_LOCAL_TEMPLATE) templateData = await templateDataRes.json();
        else templateData = await templateDataRes;

        let context = null;
        if (process.env.REACT_APP_LOCAL_TEMPLATE) context = templateData
        else context = templateData.data

        return {
            header: templateHeader, file: templateFile, context, css: templateCss,
        };
    };
}