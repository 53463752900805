const initState = {
  modalOpen: false,
  saving: false,
  message: '',
};

const SaveNewsletterReducer = (state = initState, action) => {
  switch (action.type) {
    case 'TOGGLE_SAVE_MODAL':
      return {
        ...state,
        modalOpen: !state.modalOpen,
      };

    case 'EXIT_SAVE_MODAL':
      return {
        ...state,
        message: '',
        saving: false,
        modalOpen: false,
      };

    case 'SAVING_NEWSLETTER':
    case 'UPDATING_NEWSLETTER':
      return {
        ...state,
        saving: true,
      };

    case 'SUCCESS_SAVING_NEWSLETTER':
    case 'SUCCESS_UPDATING_NEWSLETTER':
    case 'FAIL_UPDATING_NEWSLETTER':
    case 'FAIL_SAVING_NEWSLETTER':
      return {
        ...state,
        saving: false,
        message: action.message,
      };
    default:
      return state;
  }
};

export default SaveNewsletterReducer;
