import {initInternalApi} from "../newsletter-api/init";


export const toggleSaveToEmailOnAcidModal = () => ({
    type: 'TOGGLE_SAVE_EMAIL_ON_ACID_MODAL',
});

export const exitSaveToEmailOnAcidModal = () => ({
    type: 'EXIT_SAVE_EMAIL_ON_ACID_MODAL',
});

export const changeEmailOnAcidSubject = (subject) => ({
    type: 'CHANGE_EMAIL_ON_ACID_SUBJECT',
    subject,
});

export const saveToEmailOnAcid = (
    subject,
    htmlBody,
) => {
    return async (dispatch) => {
        dispatch({
            type: 'SAVE_TO_EMAIL_ON_ACID',
        });

        const api = initInternalApi();
        try {
            const response = await api.preScheduledEmailCheck(
                subject,
                htmlBody,
            );

            if ("error" in response) {
                dispatch({
                    type: 'FAIL_SAVE_TO_EMAIL_ON_ACID',
                    message: 'Failed to save the newsletter to Email on Acid',
                });
                return
            }

            dispatch({
                type: 'SUCCESS_SAVE_TO_EMAIL_ON_ACID',
                message: `Successfully Saved Newsletter to Email On Acid`,
            });
        } catch (error) {
            console.log(error, "ERROR");
            dispatch({
                type: 'FAIL_SAVE_TO_EMAIL_ON_ACID',
                message: 'Failed to save the newsletter to Email On Acid',
            });
        }
    };
};
