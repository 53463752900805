const initState = {
  modalOpen: false,
  sending: false,
  message: '',
  emailAddress: '',
  subject: '',
};

const TestEmailReducer = (state = initState, action) => {
  switch (action.type) {
    case 'SENDING_TEST_EMAIL':
      return {
        ...state,
        sending: true,
      };
    case 'UPDATE_EMAIL_ADDRESS':
      return {
        ...state,
        emailAddress: action.emailAddress,
      };

    case 'UPDATE_EMAIL_SUBJECT':
      return {
        ...state,
        subject: action.subject,
      };
    case 'SUCCESS_TEST_EMAIL':
      return {
        ...state,
        sending: false,
        message: action.message,
      };

    case 'FAIL_TEST_EMAIL':
      return {
        ...state,
        sending: false,
        message: action.message,
      };

    case 'TOGGLE_TEST_MODAL':
      return {
        ...state,
        modalOpen: !state.modalOpen,
      };

    case 'EXIT_TEST_MODAL':
      return {
        ...state,
        modalOpen: false,
        sending: false,
        message: '',
        emailAddress: '',
        subject: '',
      };
    default:
      return state;
  }
};

export default TestEmailReducer;
