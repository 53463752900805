import React from 'react';

const Templates = ({ change, templates, selectedId }) => (
  <div className="form-row">
    <select className="form-control" onChange={change} value={selectedId}>
      <option disabled value={0}>
        Select a template....
      </option>
      {templates ? (
        templates
          .sort(function (a, b) {
            if (a.name > b.name) return 1;
            if (a.name < b.name) return -1;
            return 0;
          })
          .map((template) => {
            return (
              <option
                value={template.id}
                key={template.id}
                className="form-control"
              >
                {template.name}
              </option>
            );
          })
      ) : (
        <option> No templates available </option>
      )}
    </select>
  </div>
);

export default Templates;
