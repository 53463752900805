import { combineReducers } from 'redux';
import NewsletterReducer from './newsletter-reducer';
import TestEmailReducer from './test-email-reducer';
import SaveNewsletterReducer from './save-newsletter-reducer';
import ExportAsHtmlReducer from './export-as-html-reducer';
import SaveToPostUpReducer from './save-to-post-up-reducer';
import SaveToEmailOnAcidReducer from "./save-to-email-on-acid";

export default combineReducers({
  NewsletterReducer,
  TestEmailReducer,
  SaveNewsletterReducer,
  ExportAsHtmlReducer,
  SaveToPostUpReducer,
  SaveToEmailOnAcidReducer,
});
