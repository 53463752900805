const initState = {
  modalOpen: false,
  exporting: false,
  message: '',
  fileName: '',
};

const ExportAsHtmlReducer = (state = initState, action) => {
  switch (action.type) {
    case 'TOGGLE_EXPORT_MODAL':
      return {
        ...state,
        modalOpen: !state.modalOpen,
        exporting: false,
        message: '',
        fileName: '',
      };

    case 'EXIT_EXPORT_MODAL':
      return {
        ...state,
        message: '',
        exporting: false,
        modalOpen: false,
        fileName: '',
      };

    case 'EXPORT_HTML_FILE_NAME_CHANGE':
      return {
        ...state,
        fileName: action.fileName,
      };

    case 'EXPORT_HTML_FILE':
      return {
        ...state,
        exporting: true,
      };

    default:
      return state;
  }
};

export default ExportAsHtmlReducer;
