import React from 'react';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import './release-date.css';

const ReleaseDate = ({ date, change }) => {
  return (
    <div className="form-row">
      <DatePicker
        selected={date}
        onChange={change}
        dateFormat="MMMM d, yyyy"
        customInput={<CustomInput />}
      />
    </div>
  );
};

class CustomInput extends React.Component {
  render() {
    return (
      <input
        className="form-control"
        onClick={this.props.onClick}
        value={this.props.value}
        placeholder="Release date..."
        onChange={() => {}}
      />
    );
  }
}

export default ReleaseDate;
