import React, {useEffect} from 'react';
import {connect} from 'react-redux';

import DynamicEditor from '../components/Editor/DynamicEditor';
import Brands from '../components/Editor/Brands';
import PreviewText from '../components/Editor/PreviewText';
import PreviousNewsletter from '../components/Editor/PreviousNewsletter';
import Tagline from '../components/Editor/Tagline';
import Templates from '../components/Editor/Templates';
import ReleaseDate from '../components/Editor/ReleaseDate';
import MailingLists from '../components/Editor/MailingLists';
import Buttons from '../components/Editor/Buttons';
import Footers from "../components/Editor/Footers";
import DarkMode from '../components/DarkMode'

import {
    changeBrand, changeFooter,
    changeMailingList,
    changePreviewText,
    changeReleaseDate,
    editorInputChange,
    fetchPrevNewsletter,
    prevNewsletterIdChange,
    templateChange, toggleDarkMode,
    toggleSubTypeOpen,
    updateTagline,
} from '../actions/editor-actions';

import {toggleSaveToPostUpModal} from '../actions/save-to-post-up-actions';

import {toggleSaveModal} from '../actions/save-newsletter-actions';
import {toggleTestModal} from '../actions/test-email-actions';
import {toggleExportModal} from '../actions/export-as-html-actions';
import hookActions from '../actions/hook-actions';

import * as PreProcessActions from '../actions/preprocess-actions';
import {toggleSaveToEmailOnAcidModal} from "../actions/save-to-email-on-acid";

const Editor = (props) => {
    const changeTemplate = (e) => {
        const {target} = e;
        const {value} = target;
        const templateId = Number(value);
        if (isNaN(templateId)) return;

        const template = props.templates.find(
            (template) => template.id === templateId,
        );
        const {templateId: curTmplId, templateData: curTmplContext} = props;
        props.changeTemplate(template, curTmplId, curTmplContext);
    };

    const clickEditorInput = (hook, name) => {
        if (hookActions.hasOwnProperty(hook)) {
            props.dispatch(hookActions[hook](name));
        }
    };

    useEffect(() => {
        const {templateData} = props;
        if (!templateData) return;
        let {preProcess} = templateData;
        if (!preProcess) return;
        preProcess.forEach((process) =>
            props.dispatch(PreProcessActions[process]()),
        );
    });

    let {
        changeEditorInput,
        templateData,
        changeReleaseDate,
        changeMailingList,
        changeTagline,
        changePreviewText,
        changeBrand,
        clickFetchPreviousNewsletter,
        changePreviousNewsletterId,
        toggleTestModal,
        toggleSaveModal,
        toggleExportModal,
        toggleSaveToPostUpModal,
        toggleSubTypeOn,
        toggleSaveToEmailOnAcidModal,
        changeFooter,
        toggleDarkMode,

        prevNewsletterId,
        previewText,
        brands,
        brandId,
        tagline,
        releaseDateFull,
        templates,
        templateId,
        mailingLists,
        mailingListId,
        footers,
        footerId,
        darkMode
    } = props;

    return (
        <div id="newsletter-gen">
            <div id="editor">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col">
                            <form id="newsletter-form" onSubmit={(e) => {e.preventDefault()}}>
                                <DarkMode toggleDarkMode={toggleDarkMode} darkMode={darkMode}/>
                                {/* Input & Button for Previous Newsletter */}
                                <PreviousNewsletter
                                    change={changePreviousNewsletterId}
                                    click={clickFetchPreviousNewsletter}
                                    id={prevNewsletterId}
                                />

                                {/* Preview Text Input Component */}
                                <PreviewText text={previewText} change={changePreviewText}/>

                                {/* Brands Select Component */}
                                <Brands
                                    change={changeBrand}
                                    brands={brands}
                                    selectedId={brandId}
                                />

                                {
                                    footers.length > 1 ?
                                        <Footers
                                            change={changeFooter}
                                            footers={footers}
                                            selectedId={footerId}
                                        />
                                        : null
                                }

                                {/* Tagline Text Input Component */}
                                <Tagline change={changeTagline} tagline={tagline}/>

                                {/* Release Date */}
                                <ReleaseDate
                                    change={changeReleaseDate}
                                    date={releaseDateFull}
                                />

                                {/* Template Select Input Component */}
                                <Templates
                                    change={changeTemplate}
                                    templates={templates}
                                    selectedId={templateId}
                                />

                                <MailingLists
                                    brandId={brandId}
                                    mailingLists={mailingLists}
                                    selectedID={mailingListId}
                                    change={changeMailingList}
                                />

                                {/* Dynamic Editor Component Generated by Template Chosen */}
                                <DynamicEditor
                                    changeInput={changeEditorInput}
                                    toggleSubTypeOn={toggleSubTypeOn}
                                    clickInput={clickEditorInput}
                                    templateData={templateData}
                                />

                                <Buttons
                                    toggleTestModal={toggleTestModal}
                                    toggleSaveModal={toggleSaveModal}
                                    toggleExportModal={toggleExportModal}
                                    toggleSaveToPostUpModal={toggleSaveToPostUpModal}
                                    toggleSaveToEmailOnAcidModal={toggleSaveToEmailOnAcidModal}
                                />
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


const mapStateToProps = (state) => {
    const {NewsletterReducer} = state;

    return {
        ...NewsletterReducer,
    };
};

const mapDispatchToProps = (dispatch) => ({
    dispatch,
    toggleDarkMode: () => dispatch(toggleDarkMode()),
    changeReleaseDate: (date) => dispatch(changeReleaseDate(date)),
    changeMailingList: (mailingListId) =>
        dispatch(changeMailingList(mailingListId)),
    changeTagline: (tagline) => dispatch(updateTagline(tagline)),
    changePreviewText: (previewText) => dispatch(changePreviewText(previewText)),
    changeBrand: (brandId) => dispatch(changeBrand(brandId)),
    changeFooter: (footerId) => dispatch(changeFooter(footerId)),
    clickFetchPreviousNewsletter: (prevNewsletterId) =>
        dispatch(fetchPrevNewsletter(prevNewsletterId)),
    changePreviousNewsletterId: (prevNewsletterId) =>
        dispatch(prevNewsletterIdChange(prevNewsletterId)),
    toggleSaveModal: () => dispatch(toggleSaveModal()),
    toggleTestModal: () => dispatch(toggleTestModal()),
    toggleExportModal: () => dispatch(toggleExportModal()),
    toggleSaveToPostUpModal: () => dispatch(toggleSaveToPostUpModal()),
    toggleSaveToEmailOnAcidModal: () => dispatch(toggleSaveToEmailOnAcidModal()),
    changeTemplate: (template, curTmplId, curTmplContext) =>
        dispatch(templateChange(template, curTmplId, curTmplContext)),
    toggleSubTypeOn: (subTypeId) => dispatch(toggleSubTypeOpen(subTypeId)),
    changeEditorInput: (fieldTypeId, value, type, target, dontUpdateValue) => {
        if (type === 'checkbox' && target) value = target.checked;

        if (!dontUpdateValue) dispatch(editorInputChange(fieldTypeId, value));

        if (target) {
            const hook = target.getAttribute('data-hook');
            const item = target.name;
            if (hookActions.hasOwnProperty(hook)) {
                dispatch(hookActions[hook](item, value));
            }
        }
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(Editor);
