import Navigation from "../../../../components/Navigation";
import {Link} from "react-router-dom";
import {useState} from "react";
import {toast} from "react-toastify";
import {initInternalApi} from "../../../../newsletter-api/init";

const api = initInternalApi();

const EmptyBrand = {
    name: null,
    code: null,
    logoUrl: null,
    homePageUrl: null,
    tagline: null,
    updateProfileLink: null,
    postUpBrandId: null,
    campaignId: null,
    color: null,
    glowLogoUrl: null,
    darkLogoUrl: null,
    fromName: null,
    secondaryColor: null,
}

type BrandType = {
    name: string | null,
    code: string | null,
    logoUrl: string | null,
    homePageUrl: string | null,
    tagline: string | null,
    updateProfileLink: string | null,
    postUpBrandId: number | null,
    campaignId: number | null,
    color: string | null,
    glowLogoUrl: string | null,
    darkLogoUrl: string | null,
    fromName: string | null,
    secondaryColor: string | null,
}

const CreateBrand = () => {
    const [brand, setBrand] = useState<BrandType>(EmptyBrand);

    const createBrand = async () => {
        const requiredKeys = ['name', 'code', 'logoUrl', 'homePageUrl', 'tagline', 'updateProfileLink', 'postUpBrandId', 'campaignId', 'color'];
        let ok = true;
        requiredKeys.forEach((key) => {
            // @ts-ignore
            const brandValue = brand[key];
            if (brandValue === null) {
                ok = false;
                toast.error(`Brand ${key} is required`);
            }
            if (!ok) return;
        });

        if (!ok) return;

        await api.addBrand(brand)
        setBrand(EmptyBrand);
        toast.success(`Brand ${brand.name} created successfully`);
    }

    return (
        <div>
            <Navigation/>
            <div>
                <Link to="/internal/brands">
                    <button>
                        Back To List of Brands
                    </button>
                </Link>
            </div>

            <div>
                <div>
                    <input
                        type={"text"}
                        placeholder={"Brand Name"}
                        onChange={(e) => setBrand({...brand, name: e.target.value})}
                        value={brand.name || ''}
                    />
                </div>

                <div>
                    <input
                        type={"text"}
                        placeholder={"Brand Pub Code"}
                        onChange={(e) =>
                            setBrand({...brand, code: e.target.value})}
                        value={brand.code || ''}
                    />
                </div>

                <div>
                    <input
                        type={"text"}
                        placeholder={"Brand Logo URL"}
                        onChange={(e) =>
                            setBrand({...brand, logoUrl: e.target.value})}
                        value={brand.logoUrl || ''}
                    />
                </div>

                <div>
                    <input
                        type={"text"}
                        placeholder={"Brand Home Page URL"}
                        onChange={(e) =>
                            setBrand({...brand, homePageUrl: e.target.value})}
                        value={brand.homePageUrl || ''}
                    />
                </div>

                <div>
                    <input
                        type={"text"}
                        placeholder={"Brand Tagline"}
                        onChange={(e) =>
                            setBrand({...brand, tagline: e.target.value})}
                        value={brand.tagline || ''}
                    />
                </div>

                <div>
                    <input
                        type={"text"}
                        placeholder={"Brand Profile Link"}
                        onChange={(e) =>
                            setBrand({...brand, updateProfileLink: e.target.value})}
                        value={brand.updateProfileLink || ''}
                    />
                </div>

                <div>
                    <input
                        type={"text"}
                        placeholder={"Brand Post Up Brand ID"}
                        onChange={(e) => {
                            // @ts-ignore
                            setBrand({...brand, postUpBrandId: e.target.value})
                        }}
                        value={brand.postUpBrandId || ''}
                    />
                </div>

                <div>
                    <input
                        type={"text"}
                        placeholder={"Brand Campaign ID"}
                        onChange={(e) => {
                            // @ts-ignore
                            setBrand({...brand, campaignId: e.target.value})
                        }}
                        value={brand.campaignId || ''}
                    />
                </div>

                <div>
                    <input
                        type={"text"}
                        placeholder={"Brand Color(#000000)"}
                        onChange={(e) =>
                            setBrand({...brand, color: e.target.value})}
                        value={brand.color || ''}
                    />
                </div>

                <div>
                    <input
                        type={"text"}
                        placeholder={"Brand Glow URL"}
                        onChange={(e) =>
                            setBrand({...brand, glowLogoUrl: e.target.value})}
                        value={brand.glowLogoUrl || ''}
                    />
                </div>

                <div>
                    <input
                        type={"text"}
                        placeholder={"Brand Dark Logo URL"}
                        onChange={(e) =>
                            setBrand({...brand, darkLogoUrl: e.target.value})}
                        value={brand.darkLogoUrl || ''}
                    />
                </div>

                <div>
                    <input
                        type={"text"}
                        placeholder={"From Name"}
                        onChange={(e) =>
                            setBrand({...brand, fromName: e.target.value})}
                        value={brand.fromName || ''}
                    />
                </div>

                <div>
                    <input
                        type={"text"}
                        placeholder={"Secondary Color"}
                        onChange={(e) =>
                            setBrand({...brand, secondaryColor: e.target.value})}
                        value={brand.secondaryColor || ''}
                    />
                </div>

                <div>
                    <button onClick={createBrand}>
                        Submit
                    </button>
                </div>
            </div>
        </div>
    );
}

export default CreateBrand;