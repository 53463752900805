import React from 'react';

class List extends React.Component {
  render() {
    const { id, value, hook, changeInput } = this.props;
    return (
      <div className="form-row">
        <select
          name={id}
          className="form-control"
          data-hook={hook || {}}
          onChange={(e) => {
            const { target } = e;
            const { name: fieldTypeId, value, type } = target;
            return changeInput(fieldTypeId, value, type, target, true);
          }}
        >
          <option value={0}> Pick An Option</option>
          {value ? (
            value.map((item, idx) => {
              return (
                <option value={item.title} key={item.title + idx}>
                  {item.displayName ? item.displayName : item.title}
                </option>
              );
            })
          ) : (
            <option>Pick A Brand</option>
          )}
        </select>
      </div>
    );
  }
}

export default List;
