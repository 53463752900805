import NewsletterApi from "./index";
import {Auth0ContextInterface} from "@auth0/auth0-react";

let api: NewsletterApi | null = null;
export const initInternalApi = () => {
    if (!api) api = new NewsletterApi();
    return api;
}

export const setAuth = async (auth: Auth0ContextInterface) => {
    if (!api) api = new NewsletterApi();
    await api.setAuth(auth);
    return api;
}