import Navigation from "../../../components/Navigation";
import {Link} from "react-router-dom";
import React, {useEffect, useState} from "react";
import List from "../../../components/List";
import {toast} from "react-toastify";
import Modal from 'react-modal';
import {initInternalApi} from "../../../newsletter-api/init";

type ElementType = {
    type: "select" | "text"
    placeholder?: string
    fieldName: string
    fieldValue: any
    options?: any[]
}

const getFieldValue = (fieldName: string, elements: ElementType[]): any => {
    const element = elements.find((e: any) => e.fieldName === fieldName) as any;
    if (!element) throw new Error(`Element ${fieldName} not found`)
    if (element.fieldValue === 0 || element.fieldValue === '') return null;
    return element.fieldValue;
}

const api = initInternalApi();

Modal.setAppElement('#root');

const Footer = () => {
    const [isFetching, setIsFetching] = useState(false);
    const [brandFilters, setBrandFilters] = useState<any[]>([]);
    const [editorFilters, setEditorFilters] = useState([]);
    const [templateFilters, setTemplateFilters] = useState([]);
    const [footers, setFooters] = useState<{ id: number, elements: ElementType[] }[]>([]);
    const [filteredBrandId, setFilteredBrandId] = useState(null);
    const [filteredEditorId, setFilteredEditorId] = useState(null);
    const [filteredTemplateId, setFilteredTemplateId] = useState(null);
    const [footerLoading, setFooterLoading] = useState(false);
    const [deleteFooterId, setDeleteFooterId] = useState<number | null>(null);
    const [deleteModalOpen, setModalOpen] = useState(false);

    const updateFooter = async (id: any) => {
        const footer = footers.find((b: any) => b.id === id) as any;
        if (!footer) return;

        await api.updateFooter(id, {
            brandId: getFieldValue('brandId', footer.elements),
            templateId: getFieldValue('templateId', footer.elements),
            editorId: getFieldValue('editorId', footer.elements),
            mediaGuideUrl: getFieldValue('mediaGuideUrl', footer.elements),
            imageUrl: getFieldValue('imageUrl', footer.elements),
            name: getFieldValue('name', footer.elements),
            landingUrl: getFieldValue('landingUrl', footer.elements),
            subscriptionUrl: getFieldValue('subscriptionUrl', footer.elements),
        });

        toast.success(`Footer Updated Successfully`);
    }

    const updateEntry = (id: any, fieldName: string, fieldValue: any) => {
        const footer = footers.find((b: any) => b.id === id) as any;
        if (!footer) return;

        let fieldValueParsed = isNaN(Number(fieldValue)) ? fieldValue : Number(fieldValue);

        const newFooter = {
            ...footer,
            elements: footer.elements.map((e: any) => e.fieldName === fieldName ? {
                ...e,
                fieldValue: fieldValueParsed
            } : e)
        }

        // @ts-ignore
        setFooters(footers.map((b: any) => b.id === id ? newFooter : b));
    }

    const deleteFooter = (footerId: number | string) => {
        if (isNaN(Number(footerId))) return;
        setDeleteFooterId(footerId as number);
        setModalOpen(true);
    }

    const deleteFooterConfirm = async () => {
        await api.deleteFooter(deleteFooterId);
        setFooters(footers.filter(f => f.id !== deleteFooterId));
        setModalOpen(false);
        setDeleteFooterId(null);
        toast.success(`Footer Deleted Successfully`);
    }

    useEffect(() => {
        setIsFetching(true);
        const d = async () => {
            try {
                const [brands, templates, editors] = await Promise.all([api.fetchBrandNames(), api.fetchTemplates(), api.getEditors()]);
                setBrandFilters(brands)
                setEditorFilters(editors)
                setTemplateFilters(templates)
            } catch (e) {

            } finally {
                setIsFetching(false)
            }
        }
        d().then();
    }, [])

    useEffect(() => {
        if (filteredBrandId === null && filteredTemplateId === null && filteredEditorId === null) return

        setFooterLoading(true)
        const d = async () => {
            try {
                const footers = await api.getFooters(filteredBrandId, filteredEditorId, filteredTemplateId);
                const structuredFooters = footers.map((f: any) => {
                    const els = [
                        {
                            fieldName: "brandId",
                            fieldValue: f['brandId'],
                            type: "select",
                            placeholder: "Brand",
                            options: brandFilters,
                        },
                        {
                            fieldName: "templateId",
                            fieldValue: f['templateId'],
                            type: "select",
                            placeholder: "Template",
                            options: templateFilters,
                        },
                        {
                            fieldName: "editorId",
                            fieldValue: f['editorId'],
                            type: "select",
                            placeholder: "Editor",
                            options: editorFilters,
                        },
                        {
                            fieldName: "mediaGuideUrl",
                            fieldValue: f['mediaGuideUrl'],
                            type: "text",
                            placeholder: "Media Guide URL",
                        },
                        {
                            fieldName: "imageUrl",
                            fieldValue: f['imageUrl'],
                            type: "text",
                            placeholder: "Image URL",
                        },
                        {
                            fieldName: "name",
                            fieldValue: f['name'],
                            type: "text",
                            placeholder: "Name",
                        },
                        {
                            fieldName: "landingUrl",
                            fieldValue: f['landingUrl'],
                            type: "text",
                            placeholder: "Landing URL",
                        },
                        {
                            fieldName: "subscriptionUrl",
                            fieldValue: f['subscriptionUrl'],
                            type: "text",
                            placeholder: "Subscription URL",
                        }
                    ];
                    return {
                        id: f.id,
                        elements: els,
                    }
                })
                setFooters(structuredFooters);
            } catch (e) {

            } finally {
                setFooterLoading(false)
            }
        }

        d().then();
    }, [filteredBrandId, filteredEditorId, filteredTemplateId]);


    if (isFetching) return <div>Loading...</div>

    return (
        <div>
            <Modal
                isOpen={deleteModalOpen}
                onRequestClose={() => {
                    setModalOpen(false);
                    setDeleteFooterId(null);
                }}
                contentLabel="Delete Footer Modal"
            >
                <div>
                    <button onClick={deleteFooterConfirm}>Confirm</button>
                    <button onClick={() => {
                        setModalOpen(false);
                        setDeleteFooterId(null);
                    }}>
                        Cancel
                    </button>
                </div>
            </Modal>
            <Navigation/>
            <div>
                <Link to="/internal/footers/create">
                    <button>
                        Create A Footer
                    </button>
                    <button>
                        Create An Editor
                    </button>
                </Link>
            </div>

            <FilterList
                brands={brandFilters}
                editors={editorFilters}
                templates={templateFilters}
                brandId={filteredBrandId}
                editorId={filteredEditorId}
                templateId={filteredTemplateId}
                setBrandFilterId={setFilteredBrandId}
                setEditorFilterId={setFilteredEditorId}
                setTemplateFilterId={setFilteredTemplateId}
            />

            {footerLoading ? <div>Loading...</div> : <List
                inputs={footers}
                headers={['Brand', 'Template', 'Editor', 'Media Guide URL', 'Image URL']}
                updateEntry={updateEntry}
                showHeaders={!footerLoading && !isFetching && footers.length > 0}
                saveItem={updateFooter}
                deleteItem={deleteFooter}
            />}
        </div>
    )
}

type FilterListProps = {
    brands: any[],
    editors: any[],
    templates: any[]
    brandId: number | null
    editorId: number | null
    templateId: number | null
    setBrandFilterId: (id: any) => void
    setEditorFilterId: (id: any) => void
    setTemplateFilterId: (id: any) => void
}

const FilterList = ({
                        brands,
                        editors,
                        templates,
                        brandId,
                        editorId,
                        templateId,
                        setBrandFilterId,
                        setEditorFilterId,
                        setTemplateFilterId
                    }: FilterListProps) => {
    return (
        <div>
            <div>
                <h1> Filter </h1>
            </div>

            <div>
                <label>Brand Filter</label>
                <select value={brandId || ''} onChange={(e) => {
                    if (e.target.value === 'Select a Brand') setBrandFilterId(null)
                    else setBrandFilterId(e.target.value)
                }}>
                    <option>Select a Brand</option>
                    {brands && brands.map(b => <option value={b.id} key={b.id}>{b.name}</option>)}
                </select>
            </div>

            <div>
                <label>Editor Filter</label>
                <select value={editorId || ''} onChange={(e) => {
                    if (e.target.value === 'Select an Editor') setEditorFilterId(null)
                    else setEditorFilterId(e.target.value)
                }}>
                    <option>Select an Editor</option>
                    {editors && editors.map(e => <option value={e.id} key={e.id}>{e.name}</option>)}
                </select>
            </div>

            <div>
                <label>Template Filter</label>
                <select value={templateId || ''} onChange={(e) => {
                    if (e.target.value === 'Select a Template') setTemplateFilterId(null)
                    else setTemplateFilterId(e.target.value)
                }}>
                    <option>Select a Template</option>
                    {templates && templates.map(e => <option value={e.id} key={e.id}>{e.name}</option>)}
                </select>
            </div>
        </div>
    )
}


export default Footer;