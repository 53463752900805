import React from 'react';

import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const ExportAsHtmlModal = ({
  exitModal,
  modalOpen,
  toggleModal,
  htmlFileName,
  exporting,
  htmlFileNameChange,
  exportHTMLClick,
  exportHTMLMinifyClick,
}) => {
  let exitButton = (
    <button className="btn btn-danger" onClick={exitModal}>
      Exit
    </button>
  );

  let body = null;
  let footer = null;
  if (exporting) {
    body = <div>Exporting The Newsletter as HTML</div>;
    footer = <>{exitButton}</>;
  } else {
    body = (
      <>
        <div>
          Please specify the name of the HTML file, if left blank, we will
          assign it the current date with an <b>.html</b> extension.
        </div>
        <div className="form-row">
          <input
            type="text"
            value={htmlFileName}
            onChange={htmlFileNameChange}
            className="form-control"
          />
        </div>
      </>
    );
    footer = (
      <>
        <button className="btn btn-primary" onClick={exportHTMLMinifyClick}>
          Export
        </button>
        <button className="btn btn-primary" onClick={exportHTMLClick}>
          Export Non-Minified
        </button>
        {exitButton}
      </>
    );
  }

  return (
    <Modal isOpen={modalOpen} toggle={toggleModal}>
      <ModalHeader toggle={toggleModal}>
        You Are Exporting The Newsletter As HTML
      </ModalHeader>
      <ModalBody>{body}</ModalBody>
      <ModalFooter>{footer}</ModalFooter>
    </Modal>
  );
};

export default ExportAsHtmlModal;
