import {useAuth0} from "@auth0/auth0-react";

const Login = ({location}) => {
    const {loginWithRedirect} = useAuth0();
    const state = location.state || {};
    let pathName = state.route || undefined;
    if(pathName === '/login') pathName = "/";
    return loginWithRedirect({
        appState: {
            returnTo: pathName
        }
    });
}

export default Login;