import React from 'react';
import RootType from './RootType';

const DynamicEditor = ({
  toggleSubTypeOn,
  changeInput,
  clickInput,
  templateData,
}) => {
  if (!templateData) return <div />;

  const { rootTypes, subTypes } = templateData;

  // Root Type Map that contains all of the available
  // subtypes under a certain root type to prevent re-calculating when
  // iterating over root types since they are persisted in two separate places, and we are iterating
  // over the root type first instead of the subtype....
  const rootTypeMap = {};
  const rootTypesArray = Object.keys(rootTypes)
    .map((rootTypeKey) => {
      const rootType = rootTypes[rootTypeKey];
      rootType.id = rootTypeKey;
      return rootType;
    })
    .sort((a, b) => {
      if (a.order > b.order) return 1;
      if (a.order < b.order) return -1;
      return 0;
    });
  rootTypesArray.forEach((rootType) => {
    rootTypeMap[rootType.id] = {};
  });
  Object.keys(subTypes).forEach((subTypeKey) => {
    const subType = subTypes[subTypeKey];
    subType.id = subTypeKey;
    const rootTypeId = subType.rootType;
    if (rootTypeMap[rootTypeId]) {
      rootTypeMap[rootTypeId] = {
        ...rootTypeMap[rootTypeId],
        [subTypeKey]: subType,
      };
    }
  });
  return (
    <div>
      {Object.keys(rootTypes)
        .map((rootTypeKey) => {
          return rootTypes[rootTypeKey];
        })
        .sort((a, b) => {
          if (a.order > b.order) return 1;
          if (a.order < b.order) return -1;
          return 0;
        })
        .map((rootType) => {
          const { id, name } = rootType;
          const subTypes = rootTypeMap[id];
          return (
            <RootType
              key={id}
              id={id}
              name={name}
              subTypes={subTypes}
              toggleSubTypeOn={toggleSubTypeOn}
              changeInput={changeInput}
              clickInput={clickInput}
            />
          );
        })}
    </div>
  );
};

export default DynamicEditor;
