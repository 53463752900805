import React from 'react';

const TextArea = ({ hook, value, changeInput, id }) => (
  <div className="form-row">
    <textarea
      className="form-control"
      data-hook={hook | {}}
      onChange={(e) => {
        const { target } = e;
        const { name: fieldTypeId, value, type } = target;
        return changeInput(fieldTypeId, value, type, target);
      }}
      value={value}
      name={id}
    />
  </div>
);

export default TextArea;
