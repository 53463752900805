import React from 'react';

const Button = ({hook, id, clickInput, placeholder}) => (
    <button
        type="button"
        className="btn btn-primary"
        data-hook={hook || {}}
        name={id}
        onClick={(e) => {
            e.preventDefault();
            const {target} = e;
            const hook = target.getAttribute('data-hook');
            const item = target.name;
            return clickInput(hook, item);
        }}
    >
        {placeholder}
    </button>
);

export default Button;
