export const toggleExportModal = () => ({
  type: 'TOGGLE_EXPORT_MODAL',
});

export const exitExportModal = () => ({
  type: 'EXIT_EXPORT_MODAL',
});

export const htmlFileNameChange = (e) => {
  const { target } = e;
  const { value } = target;
  return {
    type: 'EXPORT_HTML_FILE_NAME_CHANGE',
    fileName: value,
  };
};

export const exportHtmlFile = () => ({
  type: 'EXPORT_HTML_FILE',
});
