import React from 'react';

const Tagline = ({ tagline, change }) => (
  <div className="form-row">
    <input
      type="text"
      placeholder="Brand tagline..."
      value={tagline}
      onChange={(e) => {
        const { target } = e;
        const { value } = target;
        change(value);
      }}
      className="form-control"
    />
  </div>
);

export default Tagline;
