import React from 'react';
import {Link} from 'react-router-dom';

const Buttons = ({
                     toggleTestModal,
                     toggleExportModal,
                     toggleSaveModal,
                     toggleSaveToPostUpModal,
                     toggleSaveToEmailOnAcidModal,
                 }) => {
    return (
        <>
            <div className="form-row">
                <div className="btns">
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={(e) => {
                            e.preventDefault();
                            toggleSaveModal();
                        }}
                    >
                        Save Newsletter
                    </button>
                </div>
                <div className="btns">
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={(e) => {
                            e.preventDefault();
                            toggleExportModal();
                        }}
                    >
                        Export As HTML
                    </button>
                </div>
            </div>

            <div className="form-row">
                <div className="btns">
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={(e) => {
                            e.preventDefault();
                            toggleSaveToPostUpModal();
                        }}
                    >
                        Save To PostUp
                    </button>
                </div>
                <div className="btns">
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={(e) => {
                            e.preventDefault();
                            toggleTestModal();
                        }}
                    >
                        Send Test E-Mail
                    </button>
                </div>
            </div>

            <div className="form-row">
                <div className="btns">
                    <Link
                        to="/list"
                        style={{width: '99%'}}
                        className="btn btn-primary"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Link To ENews Archive
                    </Link>
                </div>
                <div className="btns">
                    <a
                        rel="noopener noreferrer"
                        href="https://resources.wtwhmedia.com/admin/image_uploader.php"
                        className="btn btn-primary"
                        style={{width: '99%'}}
                        target="_blank"
                    >
                        Link To Image Uploader
                    </a>
                </div>
            </div>
            <div className="form-row">
                <div className="btns">
                    <a
                        href="https://resources.wtwhmedia.com/admin"
                        style={{width: '99%'}}
                        className="btn btn-primary"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Link To Resources
                    </a>
                </div>
                <div className="btns">
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={(e) => {
                            e.preventDefault();
                            toggleSaveToEmailOnAcidModal();
                        }}
                    >
                        Send To Email On Acid
                    </button>
                </div>
            </div>
        </>
    );
};

export default Buttons;
