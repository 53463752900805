import React from 'react';

import {Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import Button from './Button';

const SaveToPostUpModal = ({
                               title,
                               changeTitle,
                               subject,
                               changeSubject,
                               saveToPostUp,
                               saving,
                               message,
                               exitModal,
                               modalOpen,
                               toggleModal,
                           }) => {
    const exitModalButton = (
        <Button onClick={exitModal} className="btn btn-danger" text="Exit"/>
    );

    let body = null;
    let footer = null;
    if (saving) {
        body = <div> Saving to PostUp...</div>;
        footer = <></>;
    } else if (message !== '') {
        body = <div>{message}</div>;
        footer = <>{exitModalButton}</>;
    } else {
        body = (
            <div>
                <form action="">
                    <div className="form-row">
                        <input
                            type="text"
                            value={title}
                            onChange={changeTitle}
                            className="form-control"
                            placeholder="PostUp Title"
                        />
                    </div>
                    <div className="form-row">
                        <input
                            type="text"
                            value={subject}
                            onChange={changeSubject}
                            className="form-control"
                            placeholder="Email subject..."
                        />
                    </div>
                </form>
            </div>
        );

        footer = (
            <>
                <button onClick={saveToPostUp} className="btn btn-primary">
                    Save To PostUp
                </button>
                {exitModalButton}
            </>
        );
    }

    return (
        <Modal isOpen={modalOpen} toggle={toggleModal}>
            <ModalHeader toggle={toggleModal}>Save Newsletter To PostUp</ModalHeader>
            <ModalBody>{body}</ModalBody>
            <ModalFooter>{footer}</ModalFooter>
        </Modal>
    );
};

export default SaveToPostUpModal;
