import React, {useState, useEffect, Component} from 'react';
import {EditorState} from 'draft-js';
import {Editor} from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {convertToHTML, convertFromHTML} from 'draft-convert';
import Modifier from "draft-js/lib/DraftModifier";

const SlimWysiwyg = ({id, value, hook, changeInput}) => {
    const [editorState, setEditorState] = useState(() => {
        let editorState = EditorState.createEmpty();

        if (value) {
            editorState = EditorState.createWithContent(
                convertFromHTML({
                    htmlToEntity: (nodeName, node, createEntity) => {
                        if (nodeName === 'a') {
                            return createEntity('LINK', 'MUTABLE', {url: node.href});
                        }
                    },
                })(value),
            );
        }

        return editorState;
    });

    useEffect(() => {
        const curHtml = convertToHTML({
            entityToHTML: (entity, originalText) => {
                if (entity.type !== 'LINK') {
                    return originalText;
                }

                const {url, target} = entity.data;
                const trimmedText = originalText.trim();
                const linkProps = {
                    href: url,
                    ...(target && {target}),
                    className: "wysiwyg-link",
                };

                return <a {...linkProps}>{trimmedText}</a>;
            },
        })(editorState.getCurrentContent());

        changeInput(id, curHtml, 'text', null);
    }, [editorState]);

    const handleEditorChange = (state) => {
        setEditorState(state);
    };

    return (
        <div className="rdw-editor-main">
            <Editor
                toolbarCustomButtons={[<CustomLinkOpener/>]}
                editorState={editorState}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                onEditorStateChange={handleEditorChange}
                toolbar={{
                    options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'emoji', 'remove', 'history'],
                    inline: {
                        inDropdown: false,
                        options: ['bold', 'italic', 'underline', 'strikethrough'],
                    },
                }}
            />
        </div>
    );
};

class CustomLinkOpener extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            url: "",
            openInNewWindow: false
        }

        this.add = this.add.bind(this);
        this.cancel = this.cancel.bind(this);
        this.toggle = this.toggle.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    add = (e) => {
        e.preventDefault();
        const {editorState, onChange} = this.props;

        const selection = editorState.getSelection();
        if (!selection.isCollapsed()) {
            const contentState = editorState.getCurrentContent();
            let data = {url: this.state.url}
            if (this.state.openInNewWindow) {
                data = {...data, target: '_blank'}
            }

            const contentStateWithEntity = contentState.createEntity(
                'LINK', // Entity type
                'MUTABLE',  // Mutability
                data // Entity data
            );
            const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

            const contentStateWithLink = Modifier.applyEntity(
                contentStateWithEntity,
                selection,
                entityKey
            );

            const newEditorState = EditorState.push(
                editorState,
                contentStateWithLink,
                'apply-entity'
            );

            onChange(EditorState.forceSelection(newEditorState, selection));

            this.setState({
                isOpen: false,
                url: "",
                openInNewWindow: false
            })
        }
    };

    toggle() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    cancel(e) {
        e.preventDefault();
        this.setState({
            isOpen: false,
            url: "",
            openInNewWindow: false
        })
    }

    handleChange = (e) => {
        const {target} = e;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const {name} = target;

        this.setState({
            [name]: value
        })
    }

    render() {
        return (
            <div>
                <div
                    onClick={this.toggle} className="rdw-option-wrapper">
                    <img
                        src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTUiIGhlaWdodD0iMTUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTEzLjk2Ny45NUEzLjIyNiAzLjIyNiAwIDAgMCAxMS42Ny4wMDJjLS44NyAwLTEuNjg2LjMzNy0yLjI5Ny45NDhMNy4xMDUgMy4yMThBMy4yNDcgMy4yNDcgMCAwIDAgNi4yNCA2LjI0YTMuMjI1IDMuMjI1IDAgMCAwLTMuMDIyLjg2NUwuOTUgOS4zNzNhMy4yNTMgMy4yNTMgMCAwIDAgMCA0LjU5NCAzLjIyNiAzLjIyNiAwIDAgMCAyLjI5Ny45NDhjLjg3IDAgMS42ODYtLjMzNiAyLjI5OC0uOTQ4TDcuODEyIDExLjdhMy4yNDcgMy4yNDcgMCAwIDAgLjg2NS0zLjAyMyAzLjIyNSAzLjIyNSAwIDAgMCAzLjAyMi0uODY1bDIuMjY4LTIuMjY3YTMuMjUyIDMuMjUyIDAgMCAwIDAtNC41OTV6TTcuMTA1IDEwLjk5M0w0LjgzNyAxMy4yNmEyLjIzMyAyLjIzMyAwIDAgMS0xLjU5LjY1NSAyLjIzMyAyLjIzMyAwIDAgMS0xLjU5LS42NTUgMi4yNTIgMi4yNTIgMCAwIDEgMC0zLjE4bDIuMjY4LTIuMjY4YTIuMjMyIDIuMjMyIDAgMCAxIDEuNTktLjY1NWMuNDMgMCAuODQxLjEyIDEuMTk1LjM0M0w0Ljc3MiA5LjQzOGEuNS41IDAgMSAwIC43MDcuNzA3bDEuOTM5LTEuOTM4Yy41NDUuODY4LjQ0MiAyLjAzLS4zMTMgMi43ODV6bTYuMTU1LTYuMTU1bC0yLjI2OCAyLjI2N2EyLjIzMyAyLjIzMyAwIDAgMS0xLjU5LjY1NWMtLjQzMSAwLS44NDEtLjEyLTEuMTk1LS4zNDNsMS45MzgtMS45MzhhLjUuNSAwIDEgMC0uNzA3LS43MDdMNy40OTkgNi43MWEyLjI1MiAyLjI1MiAwIDAgMSAuMzEzLTIuNzg1bDIuMjY3LTIuMjY4YTIuMjMzIDIuMjMzIDAgMCAxIDEuNTktLjY1NSAyLjIzMyAyLjIzMyAwIDAgMSAyLjI0NiAyLjI0NWMwIC42MDMtLjIzMiAxLjE2OC0uNjU1IDEuNTl6IiBmaWxsPSIjMDAwIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiLz48L3N2Zz4="
                        alt="link"
                    />
                </div>
                {this.state.isOpen ?
                    <div style={{zIndex: '100', background: 'transparent', width: '100px', height: '100px'}}>
                        <div class="rdw-link-modal">
                            <label class="rdw-link-modal-label" htmlFor="linkTarget">
                                URL
                            </label>
                            <input id="linkTarget" class="rdw-link-modal-input" name="url" value={this.state.url}
                                   onChange={this.handleChange}/>
                            <label class="rdw-link-modal-target-option" htmlFor="openInNewWindow">
                                <input id="openInNewWindow" type="checkbox" name="openInNewWindow"
                                       onChange={this.handleChange} checked={this.state.openInNewWindow}/>
                                <span>Open link in new window</span>
                            </label>
                            <span class="rdw-link-modal-buttonsection">
                                <button class="rdw-link-modal-btn" onClick={this.add}>Add</button>
                                <button class="rdw-link-modal-btn" onClick={this.cancel}>Cancel</button>
                            </span>
                        </div>
                    </div>
                    : null
                }
            </div>
        );
    }
}

export default SlimWysiwyg;
