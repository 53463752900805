import {initInternalApi} from "../newsletter-api/init";


export const sendTestEmail = (emailAddress, subject, newsletterHTML) => {
  return async (dispatch) => {
    dispatch({
      type: 'SENDING_TEST_EMAIL',
    });

    const api = initInternalApi();
    try {
      const response = await api.sendTestEmail(
        emailAddress,
        subject,
        newsletterHTML,
      );
      const { message } = response;
      dispatch({
        type: 'SUCCESS_TEST_EMAIL',
        message: message,
      });
    } catch (error) {
      dispatch({
        type: 'FAIL_TEST_EMAIL',
        message: `test message failed to send successfully to ${emailAddress}`,
      });
    }
  };
};

export const updateEmailAddress = (emailAddress) => ({
  type: 'UPDATE_EMAIL_ADDRESS',
  emailAddress,
});

export const updateSubject = (subject) => ({
  type: 'UPDATE_EMAIL_SUBJECT',
  subject,
});

export const toggleTestModal = () => ({
  type: 'TOGGLE_TEST_MODAL',
});

export const exitTestModal = () => ({
  type: 'EXIT_TEST_MODAL',
});
