import {Link} from "react-router-dom";

const Navigation = () => {

    return (
        <div>
            <Link to="/internal">
                <button>
                    Dashboard
                </button>
            </Link>
            <Link to="/internal/brands">
                <button>
                    Brands
                </button>
            </Link>

            <Link to="/internal/footers">
                <button>
                    Footers
                </button>
            </Link>
        </div>
    )
}

export default Navigation