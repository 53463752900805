import React from 'react';

import TextArea from './FieldTypes/TextArea';
import Button from './FieldTypes/Button';
import Checkbox from './FieldTypes/Checkbox';
import Color from './FieldTypes/Color';
import DefaultInput from './FieldTypes/DefaultInput';
import List from './FieldTypes/List';
import SlimWysiwyg from './FieldTypes/SlimWysiwyg';

const FieldType = ({
  placeholder,
  id,
  value,
  type,
  hook,
  changeInput,
  clickInput,
}) => {
  switch (type) {
    case 'textarea':
      return (
        <TextArea hook={hook} value={value} changeInput={changeInput} id={id} />
      );

    case 'button':
      return (
        <Button
          hook={hook}
          id={id}
          clickInput={clickInput}
          placeholder={placeholder}
        />
      );

    case 'checkbox':
      return (
        <Checkbox
          id={id}
          hook={hook}
          changeInput={changeInput}
          placeholder={placeholder}
          value={value}
        />
      );

    case 'color':
      return <Color value={value} changeInput={changeInput} id={id} />;
    case 'list':
      return (
        <List value={value} changeInput={changeInput} id={id} hook={hook} />
      );
    case 'wysiwyg':
      return (
        <SlimWysiwyg
          hook={hook}
          id={id}
          value={value}
          changeInput={changeInput}
        />
      );
    default:
      return (
        <DefaultInput
          hook={hook}
          placeholder={placeholder}
          id={id}
          value={value}
          changeInput={changeInput}
        />
      );
  }
};

export default FieldType;
