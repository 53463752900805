import Navigation from "../../../../components/Navigation";
import {Link} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {toast} from "react-toastify";
import ListSelect from "../../../../components/List/Select";
import {initInternalApi} from "../../../../newsletter-api/init";

const api = initInternalApi();

const EmptyFooter = {
    brandId: null,
    templateId: null,
    editorId: null,
    mediaGuideUrl: null,
    imageUrl: null,
    name: null,
    landingUrl: null,
    subscriptionUrl: null
}

type FooterType = {
    brandId: number | null
    templateId: number | null
    editorId: number | null
    mediaGuideUrl: string | null
    imageUrl: string | null
    name: string | null
    landingUrl: string | null
    subscriptionUrl: string | null
}

const CreateFooter = () => {
    const [footer, setFooter] = useState<FooterType>(EmptyFooter);
    const [templates, setTemplates] = useState([]);
    const [editors, setEditors] = useState([]);
    const [brands, setBrands] = useState<any[]>([]);
    const [isFetching, setIsFetching] = useState(false);
    const createFooter = async () => {
        const requiredKeys = ['brandId', 'templateId', 'editorId', 'mediaGuideUrl', 'imageUrl', 'landingUrl', 'subscriptionUrl'];
        let ok = true;
        requiredKeys.forEach((key) => {
            // @ts-ignore
            const brandValue = footer[key];
            if (brandValue === null) {
                ok = false;
                toast.error(`Footer ${key} is required`);
            }
            if (!ok) return;
        });

        if (!ok) return;

        await api.addFooter(footer)
        setFooter(EmptyFooter);
        toast.success(`Footer created successfully`);
    }

    useEffect(() => {
        setIsFetching(true)
        const d = async () => {
            const [brands, templates, editors] = await Promise.all([api.fetchBrandNames(), api.fetchTemplates(), api.getEditors()]);
            setBrands(brands);
            setTemplates(templates);
            setEditors(editors);
            setIsFetching(false)
        }

        d().then();
    }, []);

    if (isFetching) return <div>Loading...</div>

    return (
        <div>
            <Navigation/>
            <div>
                <Link to="/internal/footers">
                    <button>
                        Back To List of Footers
                    </button>
                </Link>
            </div>

            <div>
                <div>
                    <ListSelect
                        value={footer.brandId || ''}
                        onChange={(e) => {
                            // @ts-ignore
                            setFooter({...footer, brandId: e.target.value})
                        }}
                        placeholder={"Select a Brand"}
                        data={brands}
                    />
                </div>

                <div>
                    <ListSelect
                        value={footer.templateId || ''}
                        onChange={(e) => {
                            // @ts-ignore
                            setFooter({...footer, templateId: e.target.value})
                        }}
                        placeholder={"Select a Template"}
                        data={templates}
                    />
                </div>

                <div>
                    <ListSelect
                        value={footer.editorId || ''}
                        onChange={(e) => {
                            // @ts-ignore
                            setFooter({...footer, editorId: e.target.value})
                        }}
                        placeholder={"Select an Editor"}
                        data={editors}
                    />
                </div>

                <div>
                    <input
                        type="text"
                        value={footer.mediaGuideUrl || ''}
                        onChange={(e) => setFooter({...footer, mediaGuideUrl: e.target.value})}
                        placeholder={"Media Guide URL"}
                    />
                </div>
                <div>
                    <input
                        type="text"
                        value={footer.imageUrl || ''}
                        onChange={(e) => setFooter({...footer, imageUrl: e.target.value})}
                        placeholder={"Image URL"}
                    />
                </div>
                <div>
                    <input
                        type="text"
                        value={footer.name || ''}
                        onChange={(e) => setFooter({...footer, name: e.target.value})}
                        placeholder={"Name"}
                    />
                </div>
                <div>
                    <input
                        type="text"
                        value={footer.landingUrl || ''}
                        onChange={(e) => setFooter({...footer, landingUrl: e.target.value})}
                        placeholder={"Landing URL"}
                    />
                </div>
                <div>
                    <input
                        type="text"
                        value={footer.subscriptionUrl || ''}
                        onChange={(e) => setFooter({...footer, subscriptionUrl: e.target.value})}
                        placeholder={"Subscription URL"}
                    />
                </div>
                <div>
                    <button onClick={createFooter}>
                        Submit
                    </button>
                </div>
            </div>
        </div>
    );
}

export default CreateFooter;